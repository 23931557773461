import React from 'react'

import '../../style/modal.css'

export default function Modal({isOpen, onClose, children}) {

    if (!isOpen) {
        return null
    }

    return (
    <div className="modal-overlay">
            {children}
    </div>
        
    )
}