import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import "../../style/bootstrap.css"
import dotdot from "../../assets/svg/dotdotdot.svg"
import SuccessNotification from '../SuccessNotification'

export default function DropdownMenuTrainingSet ({children, ...props}) {

  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)
  const {name, id, status} = props
  const editableMenu = ["Training Data Set Details", "Copy Link", "Train new Model with Set", "Duplicate", "Delete Training Set"]
  const referenceMenu = ["Training Data Set Details", "Copy Link", "Duplicate Set and Train new Model", "Duplicate to new Training Data Set", "Delete"]
  const musicMenu = ["View Metadata", "View Attributions", "Add to Training Set", "Remove from Training Set"]
  const [dropDownElement, setDropDownElement] = React.useState()
  const [relativePath, setRelativePath] = React.useState('')
  const [showNotification, setShowNotification] = React.useState(false)
  const [position, setPosition] = useState({ top: '100%', left: '0' })

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  const handleShowNotification = () => {
    setShowNotification(true)
    }

    const handleCloseNotification = () => {
        setShowNotification(false)
    }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const copyToClipboard = async () => {
    const currentUrl = window.location.origin
    const fullUrl = `${currentUrl}${relativePath}`

    try {
      console.log(fullUrl)
      await navigator.clipboard.writeText(fullUrl)
      handleShowNotification()
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  useEffect(() => {
    if(relativePath !== '')
      {
        copyToClipboard()
      }
    
  }, [relativePath])
  function dropDownAction(action){
    switch (action) {
      case "Training Data Set Details":
        // Open the training data list of musics. 
        navigate(`/trainingsetdetails/${id}`)
        break
      case "Copy Link":
        setRelativePath(`/trainingsetdetails/${id}`)
        setIsOpen(false)
        break
      case "Train new Model with Set":
        console.log(action)
        break
      case "Duplicate":
        console.log(action)
        break
      case "Delete Training Set":
        console.log(action)
        break
      case "View Metadata": 
        console.log(action)
        break 
      case "View Attributions":
        console.log(action)
        break
      case "Add to Training Set":
        console.log(action)
        break
      case "Remove from Training Set":
        console.log(action)
        break
      default:
        console.log(action)
        break
    }
  }
  useEffect(() => {
    if (props.ismusic !== undefined ){
      setDropDownElement(musicMenu.map((item) => (
        <li key={item}> <a onClick={() => dropDownAction(item) }> {item} </a>  </li>
      )))
    }
    else if (status !== null ){
      if (status.toLowerCase() === "reference") {
       setDropDownElement(referenceMenu.map((item) => (
        <li key={item}> <a onClick={() => dropDownAction(item) }> {item} </a>  </li>
      )))
      } else {
      setDropDownElement(editableMenu.map((item) => (
        <li key={item}> <a onClick={() => dropDownAction(item) }> {item} </a>  </li>
    )))
    } 
  }
  else {
    setDropDownElement(editableMenu.map((item) => (
      <li key={item}> <a onClick={() => dropDownAction(item) }> {item} </a>  </li>
  )))
  }
  }, [status])
  
  useEffect(() => {
    if (isOpen) {
      const dropdown = dropdownRef.current
      const rect = dropdown.getBoundingClientRect()
      const spaceAbove = rect.top
      const spaceBelow = window.innerHeight - rect.bottom
      const spaceLeft = rect.left
      const spaceRight = window.innerWidth - rect.right

      let newPosition = { top: '100%', left: '0' } // Default to bottom

      if (spaceBelow < dropdown.offsetHeight && spaceAbove > dropdown.offsetHeight) {
        newPosition.top = `-${dropdown.offsetHeight}px` // Open on top

      }

      if (spaceRight < dropdown.offsetWidth && spaceLeft > dropdown.offsetWidth) {
        newPosition.left = `-${dropdown.offsetWidth}px` // Open on left
      }
      setPosition(newPosition)
    }
  }, [isOpen])
  
  return (
    <div className="dropdown" >
      <button onClick={toggleDropdown} className="w-[44px] h-6">
        <img src={dotdot} />
      </button>
      {isOpen && (
        <div ref={dropdownRef} className="dropdown-menu" style={{ position: 'absolute', top: position.top, left: position.left }}>
            {name && (<> <p> { name }  </p> <hr/> </>)}
          <ul>
            {dropDownElement}
          </ul>
        </div>
      )}
      {showNotification && (
                <SuccessNotification 
                    message={`Linked added to the clipboard`}
                    onClose={handleCloseNotification}
                />
            )}
    </div>
  )
}
