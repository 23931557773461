import React from "react"
import Modal from "./modals/Modal"
import '../style/notification.css' 
import ContactSupportSVG from '../assets/svg/contact-support.svg'

export default function ContactSupport(props) {

    const [openContactSupport, setOpenContactSupport] = React.useState(false)
    
    const closeModal = () => {
        setOpenContactSupport(false)
    };

    return(
        <div>
            <button className="text-[#2D73C8] text-base leading-6 flex items-center gap-x-2"
                    onClick={() => setOpenContactSupport(true)}>
              <img src={ContactSupportSVG} alt="contact support" />
              <span className='max-sm:hidden'>Contact </span> Support
            </button>
            <Modal isOpen={openContactSupport} onClose={closeModal}>
            <div  className="border shadow-md p-6 bg-white rounded w-[490px]">
            <div className="text-xl font-medium text-[#212529] flex items-center justify-between">
            Contact the support
            <button onClick={closeModal}>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                        d="M19.3516 7.1307L17.9416 5.7207L12.3516 11.3107L6.76156 5.7207L5.35156 7.1307L10.9416 12.7207L5.35156 18.3107L6.76156 19.7207L12.3516 14.1307L17.9416 19.7207L19.3516 18.3107L13.7616 12.7207L19.3516 7.1307Z"
                        fill="black" />
                    </svg>
                </button>
            </div>
                <hr></hr>
                <h2> Coming Soon... </h2>
            </div>
        </Modal>
        </div>
        
    )
}