import React from "react"

// import image



export default function Settings() {

    return (
        <div>
            
            <h1>Settings</h1>
        </div>
    )
}