/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRightsSummary = /* GraphQL */ `
  query GetRightsSummary($id: ID!) {
    getRightsSummary(id: $id) {
      id
      LastCatalogUpdate
      Company {
        id
        Name
        type
        createdAt
        updatedAt
        companyRightsSummaryId
        owner
        __typename
      }
      TotalTracks
      totalAudioLength
      AvgAttributesTrack
      SeperateStems
      genreBreakdown
      createdAt
      updatedAt
      rightsSummaryCompanyId
      owner
      __typename
    }
  }
`;
export const listRightsSummaries = /* GraphQL */ `
  query ListRightsSummaries(
    $filter: ModelRightsSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRightsSummaries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        LastCatalogUpdate
        TotalTracks
        totalAudioLength
        AvgAttributesTrack
        SeperateStems
        genreBreakdown
        createdAt
        updatedAt
        rightsSummaryCompanyId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      Name
      type
      Models {
        nextToken
        __typename
      }
      RightsSummary {
        id
        LastCatalogUpdate
        TotalTracks
        totalAudioLength
        AvgAttributesTrack
        SeperateStems
        genreBreakdown
        createdAt
        updatedAt
        rightsSummaryCompanyId
        owner
        __typename
      }
      licenses {
        nextToken
        __typename
      }
      licenseUsages {
        nextToken
        __typename
      }
      musics {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyRightsSummaryId
      owner
      __typename
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        type
        createdAt
        updatedAt
        RightsSummary {
          id
          LastCatalogUpdate
          TotalTracks
          totalAudioLength
          AvgAttributesTrack
          SeperateStems
          genreBreakdown
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLicense = /* GraphQL */ `
  query GetLicense($id: ID!) {
    getLicense(id: $id) {
      id
      name
      company {
        id
        Name
        type
        createdAt
        updatedAt
        companyRightsSummaryId
        owner
        __typename
      }
      usages {
        nextToken
        __typename
      }
      musics {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyLicensesId
      musicLicensesId
      owner
      __typename
    }
  }
`;
export const listLicenses = /* GraphQL */ `
  query ListLicenses(
    $filter: ModelLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLicenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        companyLicensesId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLicenseUsage = /* GraphQL */ `
  query GetLicenseUsage($id: ID!) {
    getLicenseUsage(id: $id) {
      id
      license {
        id
        name
        createdAt
        updatedAt
        companyLicensesId
        musicLicensesId
        owner
        __typename
      }
      borrowingCompany {
        id
        Name
        type
        createdAt
        updatedAt
        companyRightsSummaryId
        owner
        __typename
      }
      startDate
      endDate
      status
      models
      attributions
      createdAt
      updatedAt
      companyLicenseUsagesId
      licenseUsagesId
      owner
      __typename
    }
  }
`;
export const listLicenseUsages = /* GraphQL */ `
  query ListLicenseUsages(
    $filter: ModelLicenseUsageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLicenseUsages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        startDate
        endDate
        status
        models
        attributions
        createdAt
        updatedAt
        companyLicenseUsagesId
        licenseUsagesId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getMusicByCompany = /* Graphql */`

query getMusicByCompany($companyId: ID!
		$filter: ModelMusicFilterInput
		$limit: Int
    $nextToken: String)
{
getCompany(id: $companyId){
  musics(filter: $filter,limit:$limit, nextToken:$nextToken){
    items {
        id
        audio_file_name
        midi_file_name
        time_signature
        note_swing
        key_scale
        bpm
        tempo
        bpm_min
        bpm_max
        mood
        energy
        track_type
        description
        keywords
        instrumentation
        title
        artist
        genre
        data
        length
        totaltracks
        album
        composer
        itunes_cddb_1
        encoder
        cover
        createdAt
        updatedAt
        companyMusicsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
}


`

export const getMusic = /* GraphQL */ `
  query GetMusic($id: ID!) {
    getMusic(id: $id) {
      id
      title
      artist
      genre
      data
      length
      totaltracks
      album
      composer
      itunes_cddb_1
      encoder
      cover
      licenses {
        nextToken
        __typename
      }
      company {
        id
        Name
        type
        createdAt
        updatedAt
        companyRightsSummaryId
        owner
        __typename
      }
      playlists {
        nextToken
        __typename
      }
      MusicFromGeneratedTrack {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyMusicsId
      licenseMusicsId
      owner
      __typename
    }
  }
`;
export const listMusic = /* GraphQL */ `
  query ListMusic(
    $filter: ModelMusicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMusic(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        audio_file_name
        midi_file_name
        time_signature
        note_swing
        key_scale
        bpm
        tempo
        bpm_min
        bpm_max
        mood
        energy
        track_type
        description
        keywords
        instrumentation
        title
        artist
        genre
        data
        length
        totaltracks
        album
        composer
        itunes_cddb_1
        encoder
        cover
        createdAt
        updatedAt
        companyMusicsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPlayListMusic = /* GraphQL */ `
  query GetPlayListMusic($id: ID!) {
    getPlayListMusic(id: $id) {
      music {
        id
        title
        artist
        genre
        data
        length
        totaltracks
        album
        composer
        itunes_cddb_1
        encoder
        cover
        createdAt
        updatedAt
        companyMusicsId
        licenseMusicsId
        owner
        __typename
      }
      playlists {
        id
        name
        createdAt
        updatedAt
        owner
        __typename
      }
      id
      createdAt
      updatedAt
      musicPlaylistsId
      playlistMusicsId
      owner
      __typename
    }
  }
`;
export const listPlayListMusics = /* GraphQL */ `
  query ListPlayListMusics(
    $filter: ModelPlayListMusicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlayListMusics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        musicPlaylistsId
        playlistMusicsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPlaylist = /* GraphQL */ `
  query GetPlaylist($id: ID!) {
    getPlaylist(id: $id) {
      id
      name
      musics {
        nextToken
        __typename
      }
      models {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPlaylists = /* GraphQL */ `
  query ListPlaylists(
    $filter: ModelPlaylistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlaylists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getModelPlaylist = /* GraphQL */ `
  query GetModelPlaylist($id: ID!) {
    getModelPlaylist(id: $id) {
      id
      model {
        id
        name
        createdAt
        updatedAt
        companyModelsId
        owner
        __typename
      }
      playlists {
        id
        name
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      playlistModelsId
      modelsPlaylistsId
      owner
      __typename
    }
  }
`;
export const listModelPlaylists = /* GraphQL */ `
  query ListModelPlaylists(
    $filter: ModelModelPlaylistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModelPlaylists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        playlistModelsId
        modelsPlaylistsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGeneratedTrack = /* GraphQL */ `
  query GetGeneratedTrack($id: ID!) {
    getGeneratedTrack(id: $id) {
      id
      name
      model {
        id
        name
        createdAt
        updatedAt
        companyModelsId
        owner
        __typename
      }
      MusicFromGeneratedTrack {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      modelsGeneratedTracksId
      owner
      __typename
    }
  }
`;
export const listGeneratedTracks = /* GraphQL */ `
  query ListGeneratedTracks(
    $filter: ModelGeneratedTrackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGeneratedTracks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        modelsGeneratedTracksId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMusicFromGeneratedTrack = /* GraphQL */ `
  query GetMusicFromGeneratedTrack($id: ID!) {
    getMusicFromGeneratedTrack(id: $id) {
      id
      GeneratedTrack {
        id
        name
        createdAt
        updatedAt
        modelsGeneratedTracksId
        owner
        __typename
      }
      Music {
        id
        title
        artist
        genre
        data
        length
        totaltracks
        album
        composer
        itunes_cddb_1
        encoder
        cover
        createdAt
        updatedAt
        companyMusicsId
        licenseMusicsId
        owner
        __typename
      }
      createdAt
      updatedAt
      musicMusicFromGeneratedTrackId
      generatedTrackMusicFromGeneratedTrackId
      owner
      __typename
    }
  }
`;
export const listMusicFromGeneratedTracks = /* GraphQL */ `
  query ListMusicFromGeneratedTracks(
    $filter: ModelMusicFromGeneratedTrackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMusicFromGeneratedTracks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        musicMusicFromGeneratedTrackId
        generatedTrackMusicFromGeneratedTrackId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getModels = /* GraphQL */ `
  query GetModels($id: ID!) {
    getModels(id: $id) {
      id
      name
      playlists {
        nextToken
        __typename
      }
      generatedTracks {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyModelsId
      owner
      __typename
    }
  }
`;
export const listModels = /* GraphQL */ `
  query ListModels(
    $filter: ModelModelsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        companyModelsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotePriv = /* GraphQL */ `
  query GetNotePriv($id: ID!) {
    getNotePriv(id: $id) {
      id
      name
      description
      image
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listNotePrivs = /* GraphQL */ `
  query ListNotePrivs(
    $filter: ModelNotePrivFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotePrivs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNote = /* GraphQL */ `
  query GetNote($id: ID!) {
    getNote(id: $id) {
      id
      name
      description
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNotes = /* GraphQL */ `
  query ListNotes(
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        image
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listCompaniesByName = /* GraphQL */ `
  query ListCompaniesByName(
    $Name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompaniesByName(
      Name: $Name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Name
        createdAt
        updatedAt
        companyRightsSummaryId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getCompanyLicensee = /* GraphQL */ `
  query GetLicenseesOfCompany($companyID: ID!) {
    listLicenses(filter: { companyLicensesId: { eq: $companyID } }) {
      items {
        id
        name
        usages {
          items {
            id
            startDate
            endDate
            status
            models
            attributions
            borrowingCompany {
              id
              Name
            }
          }
        }
      }
    }
  }
`;

export const listCompanyLicenses = /* GraphQL */ `
  query listCompanyLicenses(
    $filter: ModelLicenseUsageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLicenseUsages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        startDate
        endDate
        status
        models
        attributions
        createdAt
        updatedAt
        companyLicenseUsagesId
        license {
          id
          name
          Metadata {
            AvgSgnKey
            TotalTracks
            totalAudioLength
            SeperateStems
            UniqueSngKey
            ArtistByNumberOfTrack
            AvgAttributesTrack
            GenresPercentage
            UniqueArtists
          }
          musics {
            items{
              music {
                length
              }
            }
          }
          company {
            id
            Name
          }
        }
      }
      nextToken
      __typename
    }
  }
`;

export const listPlaylistPerCompany = /* GraphQL */ `
query ListPlaylist {
  listPlaylists {
    items {
      id
      description
      status
      musics {
        items {
          id
        }
      }
      name
      Licenses {
        items {
          name
        }
      }
    }
    nextToken
  }
}

`;

export const listModelPerPlaylist = /* GraphQL */ `
query listModelPerPlaylist {
  listModelPlaylists {
    items {
      model {
        name
        Status
        LastTrained
        generatedTracks {
          items {
            id
          }
        }
      }
    }
  }
}
`;

export const listLicenseMusics = /* GraphQL */ `
query ListLicenseMusics( 
  $filter: ModelLicenseMusicFilterInput
  $limit: Int
  $nextToken: String) {
  listLicenseMusics(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      musicLicensesId
      licence {
        name
        company {
          Name
        }
      }
      music {
        artist
        data
        genre
        title
        id
        length
      }
    }
  }
}
`;
