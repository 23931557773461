import React from 'react'
import { Input } from '@nextui-org/input'
import { Button } from '@nextui-org/button'
import MusicAIPassword from './MusicAIPassword'

import anonymousUserImage from '../../assets/svg/anno-user.svg'
import pickImageSvg from '../../assets/svg/import-image.svg'
import { fetchUserAttributes } from 'aws-amplify/auth';
import { AccountSettings, useAuthenticator } from '@aws-amplify/ui-react'
import { updateUserAttributes, confirmUserAttribute } from 'aws-amplify/auth';
import VerifyCodePopup from '../VerifyCode';
import SuccessNotification from '../SuccessNotification'
import { DataContext } from '../../contexts/Site'
import { uploadData, getUrl} from 'aws-amplify/storage';


// image


function UserDetails() {

  const [showNotification, setShowNotification] = React.useState(false)
  const [notificationMessage, setNotificationMessage] = React.useState(false)
  const { user } = useAuthenticator((context) => [
    context.user
  ])
  
  
  const handleShowNotification = () => {
    setShowNotification(true)
  }

  const handleCloseNotification = () => {
      setShowNotification(false)
  }
  // changing the password component. 
  const passwordComponents = {
    CurrentPasswordField: ({ 
      fieldValidationErrors,
      name,
      onBlur,
      onChange
    }) => 
       (
        <>
          <MusicAIPassword fieldValidationErrors={fieldValidationErrors} name={name} onBlur={onBlur} onChange={onChange} label='Current Password'/>
        </>
      )
    ,
    NewPasswordField: ({ 
      fieldValidationErrors,
      name,
      onBlur,
      onChange
    }) => (
      <>
        <MusicAIPassword fieldValidationErrors={fieldValidationErrors} name={name} onBlur={onBlur} onChange={onChange} label='New Password'/>
      </>
    ),
    ConfirmPasswordField: ({ 
      fieldValidationErrors,
      name,
      onBlur,
      onChange
    }) => (
      <>
        <MusicAIPassword fieldValidationErrors={fieldValidationErrors} name={name} onBlur={onBlur} onChange={onChange} label='Confirm New Password'/>
      </>
    ),
    
    SubmitButton: ({ onClick, isDisabled }) => (
      <Button type="Submit" radius="sm" color="primary" className="w-fit" onClick={onClick} disabled={isDisabled}>
          Update password
      </Button>
    )
    
  }

  const [userDetailsFormData, setuserDetailsFormData] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  })

  async function getUserData(){
    const userdetail = await fetchUserAttributes();
    console.log(userdetail)
    setuserDetailsFormData({
      firstName: userdetail?.given_name,
      lastName: userdetail?.family_name,
      email: userdetail?.email,
      phoneNumber: userdetail?.phone_number
    })
  }

  React.useEffect(() => {
    getUserData()
  },[])

  const handleChange = (e) => {
    const { name, value } = e.target
    setuserDetailsFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const [isPopupOpen, setIsPopupOpen] = React.useState(false);

  const handleVerify = async (code) => {
    try{
      const userAttributeKey = 'email'
      const confirmationCode = code
      const response = await confirmUserAttribute({ userAttributeKey, confirmationCode })
      setNotificationMessage("Update Done, but need Email validation")
    }catch(error){
      setNotificationMessage("Error: Verification Failed")
    }
    finally {
      setShowNotification(true)
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    // Process form data here
    console.log('Form data:', userDetailsFormData)
    try {
      const userAttributes = Object.fromEntries(
        Object.entries({
          email: userDetailsFormData.email,
          given_name: userDetailsFormData.firstName,
          family_name: userDetailsFormData.lastName,
          phone_number: userDetailsFormData.phoneNumber,
        }).filter(([_, value]) => value)
      );
      const updateResponse = await updateUserAttributes({
        userAttributes: userAttributes
      });
      
      // Updating the user attribute
      if(!updateResponse?.email.isUpdated && updateResponse?.email.nextStep.updateAttributeStep === "CONFIRM_ATTRIBUTE_WITH_CODE")
      {
        // This mean where are going to open and enter the next email attribute
        setIsPopupOpen(true)
        setNotificationMessage("Update Done, but need Email validation")
      }
      setNotificationMessage("The update happening without error")
    } catch (error){
      setNotificationMessage(`Error: ${error.message}`)
      console.log(error.message)
    } finally{
      setShowNotification(true)
    }
    

  }

  const handleSuccess = () => {
    alert('password is successfully changed!')
  }

  /*
    Handle image
    
  */

  const [userProfilePhoto, setUserProfilePhoto] = React.useState('')
  async function linkToStorageFile(){
    const url = await getUrl({
      path: `usersprofile/${user.username}`
    });
    console.log(url.url.toString())
    setUserProfilePhoto(url.url.toString())
  }
  
  React.useEffect(() => {
    linkToStorageFile()
  }, [user])
  
  const handleFileChange = (event) => {
    const file = event.target.files[0]
    console.log("I am in there")
    if (file) {
      console.log("I am in there")
      const response = uploadData({
            path: `usersprofile/${user?.userId}`,
            data: file,
        })
      console.log(response)
    }
  }
  return (
    <div className="mt-20">
      <h2 className="mb-9 font-medium text-2xl text-[#304050]">User Details</h2>
      <section id="user-details" className="flex flex-col-reverse sm:flex-row justify-between gap-x-4 gap-y-12 xl:gap-x-[200px]">
        <div className="flex flex-col gap-y-6 w-full max-w-[500px] xl:max-w-[600px]">
          <form onSubmit={handleSubmit}>
            <Input
              type="text"
              label="First Name"
              labelPlacement={'outside'}
              placeholder="John"
              radius="sm"
              name="firstName"
              value={userDetailsFormData.firstName}
              onChange={handleChange}
            />
            <Input
              type="text"
              label="Last Name"
              labelPlacement={'outside'}
              placeholder="Doe"
              radius="sm"
              name="lastName"
              value={userDetailsFormData.lastName}
              onChange={handleChange}
            />
            <Input
              type="email"
              label="Email address"
              labelPlacement={'outside'}
              placeholder="johndoe@gmail.com"
              radius="sm"
              name="email"
              value={userDetailsFormData.email}
              onChange={handleChange}
            />
            <Input
              type="text"
              label="Phone Number"
              labelPlacement={'outside'}
              placeholder="555 867-5309"
              radius="sm"
              name="phoneNumber"
              value={userDetailsFormData.phoneNumber}
              onChange={handleChange}
            />
            <Button  type="submit" color="primary" className="w-fit" radius="sm">
              Save
            </Button>
          </form>
          <VerifyCodePopup
            isOpen={isPopupOpen}
            onRequestClose={() => setIsPopupOpen(false)}
            onVerify={handleVerify}
          />
        </div>

        <div className="flex flex-col sm:items-center gap-1">
          <p className="font-medium text-base text-[#304050]">Profile image</p>
          <label className="relative cursor-pointer">
            <img
              src={anonymousUserImage}
              width={90}
              height={90}
              alt="profile picture"
              className="rounded"
            />
            <img
              src={pickImageSvg}
              width={36}
              height={36}
              alt="import image"
              className="w-9 h-9 absolute -bottom-[6px] -right-[6px] bg-white rounded-full"
            />
            <input
              type="file"
              accept="image/png, image/jpeg"
              className="invisible w-1 h-1 absolute top-0 left-0"
              // onChange={handleFileChange}
            />
          </label>
        </div>
      </section>

      <section id="credentials" className="mt-20 max-w-[600px]">
        <h2 className="font-medium text-2xl text-[#304050] mb-9">
          Update password
        </h2>
        <div className="flex flex-col gap-y-6 mb-12">
          <AccountSettings.ChangePassword onSuccess={handleSuccess} components={passwordComponents}/>
        </div>
      </section>
      {showNotification && (
                <SuccessNotification 
                    message={notificationMessage}
                    onClose={handleCloseNotification}
                />
            )}
    </div>
  )
}

export default UserDetails
