function TermsAndService() {
  return (
    <div className="mt-20 text-[#304050]">
      <h2 className="mb-9 font-medium text-2xl leading-[30px]">
        Terms of Service
      </h2>

      <section>
        <h3 className="font-bold text-xl">Enim blandit volutpat</h3>
        <br />
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Enim
          blandit volutpat maecenas volutpat blandit aliquam etiam erat.
          Pellentesque eu tincidunt tortor aliquam nulla facilisi. Et malesuada
          fames ac turpis. Euismod elementum nisi quis eleifend quam adipiscing
          vitae proin sagittis. Feugiat in ante metus dictum at tempor commodo
          ullamcorper a. Adipiscing elit ut aliquam purus. Donec adipiscing
          tristique risus nec feugiat. Tincidunt id aliquet risus feugiat in
          ante metus dictum. Dolor sit amet consectetur adipiscing elit. Vitae
          elementum curabitur vitae nunc sed velit dignissim sodales. Viverra
          mauris in aliquam sem fringilla. Sagittis nisl rhoncus mattis rhoncus
          urna neque viverra justo nec. Volutpat lacus laoreet non curabitur
          gravida arcu ac.
          <br />
          Erat imperdiet sed euismod nisi porta lorem. Aliquet eget sit amet
          tellus. Neque sodales ut etiam sit amet nisl purus in. Vel orci porta
          non pulvinar neque laoreet. Aliquam malesuada bibendum arcu vitae
          elementum curabitur. Varius vel pharetra vel turpis nunc eget lorem.
          Interdum velit laoreet id donec ultrices. Enim eu turpis egestas
          pretium. Vel eros donec ac odio tempor orci dapibus ultrices in.
          Tempus quam pellentesque nec nam aliquam sem et tortor. Eleifend quam
          adipiscing vitae proin sagittis. Non nisi est sit amet facilisis magna
          etiam. In aliquam sem fringilla ut morbi tincidunt augue. Eget velit
          aliquet sagittis id consectetur purus ut faucibus. Sit amet purus
          gravida quis blandit turpis. Sit amet aliquam id diam maecenas. Augue
          mauris augue neque gravida in fermentum et sollicitudin. Cras ornare
          arcu dui vivamus arcu felis.
        </p>
      </section>
    </div>
  )
}

export default TermsAndService
