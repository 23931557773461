import React from 'react'

export default function Loading() {
  return (
    <div className='flex justify-center py-32 w-full'>
      <svg
        width="101"
        height="101"
        viewBox="0 0 101 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="animate-spin"
      >
        <ellipse
          cx="50.765"
          cy="50.3887"
          rx="38.8158"
          ry="38.7417"
          stroke="#EFF0F0"
          stroke-width="18"
        />
        <path
          d="M89.5808 50.3887C89.5808 71.7852 72.2024 89.1304 50.765 89.1304C29.3276 89.1304 11.9492 71.7852 11.9492 50.3887C11.9492 28.9922 29.3276 11.647 50.765 11.647"
          stroke="#82ABDE"
          stroke-width="18"
          stroke-linecap="round"
        />
      </svg>
    </div>
  )
}
