import React from "react"
import { DataContext } from "../contexts/Site"


export default function AccessHistory(){
    const {borrowingCompanies} = React.useContext(DataContext)

    function formatDate(date) {
        
        const month = date.getUTCMonth() + 1;
        const day = date.getUTCDate();
        const year = date.getUTCFullYear().toString().slice(-2);
        
        return `${month}/${day}/${year}`;
      }

    function actionAndDate(usage) {
        const today = new Date()
        const startDate = new Date(usage.startDate)
        const endDate = new Date(usage.endDate)
        
        if (endDate < today) {
            return (
            <>
            <td>Expired</td>
            <td>{formatDate(endDate)}</td>
            </>)
        }
        else if (startDate < today ) {
            return (
                <>
                <td>Activated</td>
                <td>{formatDate(startDate)}</td>
                </>)
        }
        else {
            // Not active yet
            return (
                <>
                <td>To be active</td>
                <td>{formatDate(startDate)}</td>
                </>)
        }

        
    }

    const trElements = borrowingCompanies === null ? [] : borrowingCompanies.map((item) => (
        item.usages.items.map((nestedItem) => (
            <tr key={`${nestedItem.id}`}>
                {/*}<td>{item.name}</td>{*/}
                <td>{nestedItem.borrowingCompany.Name}</td>
                {actionAndDate(nestedItem)}
            </tr>
        ))
    ))
    return (
        <section className="access-history">
        <div className="heading-with-button">
          <h2>Access History</h2>
        </div>

        <table className="data-table">
          <thead>
            <tr>
              {/*}<th>Licensee</th>{*/}
              <th>Company Name</th>
              <th>Action</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {trElements}
          </tbody>
        </table>
      </section>
    )
}
