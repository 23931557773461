import React from "react"
import { useNavigate } from 'react-router-dom';

export default function CatalogResume(props) {
    const {LastCatalogUpdate, TotalTracks, companyName} = props
    const navigate = useNavigate();

    return (
        <section className="overview">
            <p>Catalog Data Viewer</p>
            <h1>{companyName}</h1>
            <div className="detail max-sm:flex-col max-sm:!gap-y-2">
            <span>
                <b>Last Catalog Update:</b> {LastCatalogUpdate === null ? "-" : LastCatalogUpdate }
            </span>
            <span>
                <b>Total Tracks:</b> {Number(TotalTracks).toLocaleString('en-US')}
            </span>
            </div>
            <button className="yellow-button" onClick={() => navigate("/datacatalog/explorecatalog") }> Explore Catalog & Metadata </button>
      </section>
    )
}
