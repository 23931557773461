import React, { useEffect } from "react"
import Modal from "./modals/Modal"
import addinrect from "../assets/svg/add-in-rect.svg"
import { DataContext } from "../contexts/Site"
import { listCompanies, listLicenses } from "../modified-graphql/queries"
import SuccessNotification from "./SuccessNotification"
import { createLicenseUsage } from "../modified-graphql/mutations"
import {listLicenseUsages} from '../modified-graphql/queries'
import { generateClient } from "aws-amplify/api"
import '../style/notification.css' 
import { getCurrentAWSDate } from "../utils"
import { CgAttribution } from "react-icons/cg"
import { isInThePast } from "../utils"

import {DatePicker} from "@nextui-org/date-picker";
import {parseDate} from "@internationalized/date";

const API = generateClient()

export default function NewLicencee(){
    const [isModalOpen, setModalOpen] = React.useState(false);
    const [selectedCompany, setSelectedCompany] = React.useState('')
    const [selectedLicenses, setSelectedLicenses] = React.useState('')
    const [aiCompanies, setAiCompanies] = React.useState([])
    const [companyLicenses, setCompanyLicenses] = React.useState([])
    const [date, setDate] = React.useState(parseDate(new Date().toISOString().split("T")[0]))
    const [isFormFilled, setIsFormFilled] = React.useState(false)


    const {companyName, companyId, setUpdate} = React.useContext(DataContext)
   
    const [showNotification, setShowNotification] = React.useState(false)
    const [notificationMessage, setNotificationMessage]  = React.useState(false)
    


    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleShowNotification = () => {
        setShowNotification(true)
    }

    const handleCloseNotification = () => {
        setShowNotification(false)
    }

    // Form management 
    const handleCompanyChange = (event) => {
        setSelectedCompany(event.target.value);
        setSelectedLicenses(event.target.value)
    };
    
    const handleLicensesChange = (event) => {
        setSelectedLicenses(event.target.value)
    }
    
    const handleDateChange = (event) => {
        setDate(event.target.value)
    }
    
    async function handleSubmit (event) {
        event.preventDefault();
        // console.log('Selected Company ID:', selectedCompany);
        // console.log(`License ID: ${selectedLicenses}, Date: ${getCurrentAWSDate()}, selectedCompany: ${selectedCompany}`)
        // Check first if the trio exist
        const apiData = await API.graphql({
            query: listLicenseUsages, 
            variables: { filter: {
                    and: [
                        {companyLicenseUsagesId: {eq: selectedCompany}},
                        {licenseUsagesId: {eq: selectedLicenses}},
                        {status : {eq: "Activated"}}
                    ]
                }
            }
        })
        console.log(apiData.data.listLicenseUsages.items.length)
        if (apiData.data.listLicenseUsages.items.length > 0) {
            setNotificationMessage("This licensee already has a usage right for that license.")
        }
        else {
            const  input = {
                companyLicenseUsagesId: selectedCompany, 
                licenseUsagesId: selectedLicenses, 
                startDate: `${getCurrentAWSDate()}T00:00:00.000Z`, 
                endDate: `${date.toString()}T00:00:00.000Z`,
                attributions: 0,
                status: "Activated"
            }
    
            await API.graphql({
                query: createLicenseUsage,
                variables: { input },
              });
            setUpdate((prevValue) => prevValue+1)
            setNotificationMessage("The Licensee has been added")
        }
        
        handleShowNotification()
        handleCancel()
    };

    const handleCancel = () => {
        setSelectedLicenses('')
        setSelectedCompany('')
        setDate(parseDate(new Date().toISOString().split("T")[0]))
        setModalOpen(false)
    }

    // Fetch the data for the companies and for the licenses

    async function fetchCompany() {
        let apiData = await API.graphql({query: listCompanies, variables: {filter: {and: [{Name:  { ne : companyName }}, {type: {eq: "AI"}}]}}})
        // console.log(apiData.data.listCompanies.items)
        setAiCompanies(apiData.data.listCompanies.items)
    }
    async function fetchLicense() {
        let apiData = await API.graphql({query: listLicenses, variables: {filter: {companyLicensesId:  { eq : companyId }}}})
        setCompanyLicenses(apiData.data.listLicenses.items)
        // console.log(apiData.data.listLicenses.items)
    }

    
    React.useEffect(() => {
        fetchCompany()
        fetchLicense()
    }, [])

    // handle when the submit button become available or not

    React.useEffect(() => {
        if (selectedCompany && !isInThePast(date)) {
        /*if (selectedCompany && selectedLicenses && date) {*/
            setIsFormFilled(true)
        } else {
            setIsFormFilled(false)
        }
    }, [selectedCompany, date])

    return (
        <div>
            <button className="yellow-button"  onClick={openModal}>
                <img src={addinrect} width="14" height="14" draggable="false" />
                Add New Licensee
            </button>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <div className="border shadow-md p-6 bg-white rounded w-[490px]">
                    <div className="text-xl font-medium text-[#212529] flex items-center justify-between">
                    Add New Licensee
                    <button onClick={closeModal}>
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M19.3516 6.9609L17.9416 5.5509L12.3516 11.1409L6.76156 5.5509L5.35156 6.9609L10.9416 12.5509L5.35156 18.1409L6.76156 19.5509L12.3516 13.9609L17.9416 19.5509L19.3516 18.1409L13.7616 12.5509L19.3516 6.9609Z"
                            fill="black" />
                        </svg>

                    </button>
                    </div>
                    <form  onSubmit={handleSubmit}>
                        
                        <label htmlFor="aicompany" className="mt-9 mb-3 flex gap-x-5 items-center ">
                            <p className="font-bold text-sm text-[#454545]"> 
                            Available Licensees </p>
                            <div className='relative pointer-events-none'>
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" className='peer cursor-pointer'>
                                    <g clip-path="url(#clip0_4643_58044)">
                                    <rect x="0.351562" y="0.0506592" width="16" height="16" rx="8" fill="#454545" />
                                    <path d="M7.61995 13.0507V5.95266H9.73395V13.0507H7.61995ZM7.53595 4.79066V2.81666H9.80395V4.79066H7.53595Z"
                                        fill="white" />
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_4643_58044">
                                        <rect x="0.351562" y="0.0506592" width="16" height="16" rx="8" fill="white" />
                                    </clipPath>
                                    </defs>
                                </svg>
                                <div className='opacity-0 peer-hover:opacity-100 transition-all duration-200 absolute left-8 top-1/2 -translate-y-1/2 bg-[#212529] w-[200px] py-1 px-2 rounded text-sm text-white somms-tooltip-left'>
                                Licensees must have an account on our platform. 
                                <br />
                                <br />
                                Adding a new licensee will give them full access to view and use your training data on our platform during the active dates.
                                </div>
                            </div>
                        </label>
                        
                        <select id="aicompany" 
                            className="w-[286px] border border-[#89949F] rounded p-[6px_12px] outline-none leading-6"
                            value={selectedCompany}
                            onChange={handleCompanyChange}>
                                <option value="" disabled>
                                    -- Select a Company --
                                </option>
                                {aiCompanies.map((company) => (
                                    <option key={company.id} value={company.id}>
                                        {company.Name}
                                    </option>
                                ))}
                        </select>
                        {/* Licenses */}
                        {/*}<label htmlFor="licenses" className="mt-9 mb-3 flex gap-x-5 items-center">
                            <p className="font-bold text-sm text-[#454545]"> 
                            Available Licenses </p>
                            
                        </label>{*/}
                        
                        {/*}<select id="licenses" 
                            className="w-[286px] border border-[#89949F] rounded p-[6px_12px] outline-none leading-6"
                            value={selectedLicenses}
                            onChange={handleLicensesChange}>
                                <option value="" disabled>
                                    -- Select a License --
                                </option>
                                {companyLicenses.map((license) => (
                                    <option key={license.id} value={license.id}>
                                        {license.name}
                                    </option>
                                ))}
                        </select>{*/}
                        <div style={{display: "none"}}>
                        <select id="licenses" type="hidden" display="none"
                            className="w-[286px] border border-[#89949F] rounded p-[6px_12px] outline-none leading-6"
                            value={selectedLicenses}
                            onChange={handleLicensesChange}>
                                {companyLicenses.map((license) => (
                                    <option key={license.id} value={companyLicenses[0].id}>
                                        {companyLicenses[0].name}
                                    </option>
                                ))}
                        </select>
                        </div>

                        <div className="mt-9">
                        <p className="font-bold text-sm text-[#454545]">Expiration Date</p>
                        <DatePicker
                            id="date"
                            value={date}
                            onChange={setDate}
                            showMonthAndYearPickers
                            className="mt-3 max-w-[286px]"
                            variant="bordered"
                            aria-label='Expiration date'
                        />
                        </div>

                        <div className="mt-20 flex justify-end gap-x-3 text-sm">
                        <button className="bg-[#EAECEF] rounded py-2 px-4" onClick={handleCancel} >Cancel</button>
                        <button type="submit" disabled={!isFormFilled}
                            className= { !isFormFilled ? "disabled:bg-[#EAECEF] bg-[#2D73C8] rounded py-2 px-4 disabled:text-[#89949F] text-white": "bg-[#EAECEF] rounded py-2 px-4" }>Add New
                            Licensee</button>
                        </div>
                    </form>
                    
                </div>
            </Modal>
            {showNotification && (
                <SuccessNotification 
                    message={notificationMessage}
                    onClose={handleCloseNotification}
                />
            )}
        </div>
        
    )

}
