import React from "react"
import audio from "../assets/svg/audio.svg"
import { useNavigate } from "react-router-dom"

export default function ModelTrainingHistory() {

    const navigate = useNavigate()
  return (
    <section className="training-history">
      <div className="heading-with-button flex-wrap">
        <h2>Model Training History</h2>
        <button className="yellow-button !h-8"
                  onClick={() => navigate("/generatedtracks")}>
          <img src={audio} width="14" height="14" />
          Listen to Inferences
        </button>
      </div>
      <div className='overflow-x-auto'>
        <table className="data-table">
          <thead>
            <tr>
              <th>Licensee</th>
              <th>Training Run</th>
              <th>Status</th>
              <th>Model</th>
              <th>Attributions</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <td>Udio</td>
              <td>1/7/24-2/9/24</td>
              <td>Complete</td>
              <td>UdioModel_12</td>
              <td>6,603</td>
              <td>
                <button className="play-inference">
                  <img src="./assets/svg/audio.svg" width="16" height="16" />
                </button>
              </td>
            </tr> */}
          </tbody>
        </table>
        <p className='text-center pb-2'>No model has been trained yet.</p>
      </div>
    </section>
  )
}
