
import React from "react"
import Modal from "./modals/Modal"
import { DataContext } from "../contexts/Site"
import SuccessNotification from "./SuccessNotification"
import {updateLicenseUsage} from "../modified-graphql/mutations"
import { generateClient } from "aws-amplify/api"
import '../style/notification.css' 
import { formatDatePicketDate, isInThePast } from "../utils"

import {DatePicker} from "@nextui-org/date-picker";
import {parseDate} from "@internationalized/date";


const API = generateClient()

export default function ChangeExpirationDate(props){
    const {modalData, openChangeExpirationDate, setOpenChangeExpirationDate } = props
    const [date, setDate] = React.useState(parseDate(new Date().toISOString().split("T")[0]))
    const [isFormFilled, setIsFormFilled] = React.useState(false)


    const {companyName, companyId, setUpdate} = React.useContext(DataContext)
   
    const [showNotification, setShowNotification] = React.useState(false)
    const [notificationMessage, setNotificationMessage] = React.useState(false)
    

    function formatDate(dateString) {
        
        const date = new Date(dateString);
      
        
        const month = date.getUTCMonth() + 1;
        const day = date.getUTCDate();
        const year = date.getUTCFullYear().toString().slice(-2);
        
        return `${month}-${day}-${year}`;
    }
    
    // const openModal = () => {
    //     setModalOpen(true);
    // };

    const closeModal = () => {
        setOpenChangeExpirationDate(false);
    };

    const handleShowNotification = () => {
        setShowNotification(true)
    }

    const handleCloseNotification = () => {
        setShowNotification(false)
    }

    const handleDateChange = (date) => {
        // console.log(formatDatePicketDate(event))
        console.log(date.toString())
        setDate(date)
    }
    
    async function handleSubmit (event) {
        event.preventDefault();
        
        const formatedDate = date.toString()
        // Check if the date is greater than today
        if(isInThePast(formatedDate)) {
            setNotificationMessage("Error: The Date is in the past, cannot update")
        }
        else{
            const  input = {
                id: modalData.id, 
                endDate: `${formatedDate}T00:00:00.000Z`,
                status: "ACTIVATED"
            }
            await API.graphql({
                query: updateLicenseUsage,
                variables: { input },
              });
            setUpdate((prevValue) => prevValue+1)
            setNotificationMessage("Expiration date changed")
        }
        
        handleShowNotification()
        handleCancel()
    };

    const handleCancel = () => {
        setDate(parseDate(new Date().toISOString().split("T")[0]))
        closeModal()
    }

    // handle when the submit button become available or not

    React.useEffect(() => {
        if (!isInThePast(date)) {
            setIsFormFilled(true)
        } else {
            setIsFormFilled(false)
        }
    }, [date])
    
    return (
        <div>
            <Modal isOpen={openChangeExpirationDate} onClose={closeModal}>
                <div className="border shadow-md p-6 bg-white rounded w-[490px]">
                    <div className="text-xl font-medium text-[#212529] flex items-start justify-between">
                    Change Expiration Date for {modalData !== '' && modalData !== undefined ? modalData.borrowingCompany.Name: ""}
                    <button onClick={closeModal}>
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M19.3516 6.9609L17.9416 5.5509L12.3516 11.1409L6.76156 5.5509L5.35156 6.9609L10.9416 12.5509L5.35156 18.1409L6.76156 19.5509L12.3516 13.9609L17.9416 19.5509L19.3516 18.1409L13.7616 12.5509L19.3516 6.9609Z"
                            fill="black" />
                        </svg>

                    </button>
                    </div>
                    <form  onSubmit={handleSubmit}>
                        
                        <div className="mt-9 mb-3 flex gap-x-5 items-center">
                            <p className="font-bold text-sm text-[#454545]"> 
                            Current Expiration Date </p>
                            
                        </div>
                        <div>
                                <p> {modalData !== '' && modalData !== undefined ? formatDate(modalData.endDate): ""} </p>
                            </div>
                        
                        <div className="mt-9">
                        <p className="font-bold text-sm text-[#454545]">New Expiration Date</p>
                        <DatePicker
                            id="date"
                            value={date}
                            onChange={handleDateChange}
                            showMonthAndYearPickers
                            className="mt-3 max-w-[286px]"
                            variant="bordered"
                            aria-label='Expiration date'
                            radius='sm'
                        />
                        </div>

                        <div className="mt-20 flex justify-end gap-x-3 text-sm">
                        <button className="bg-[#EAECEF] rounded py-2 px-4" onClick={handleCancel} >Cancel</button>
                        <button type="submit" disabled={!isFormFilled}
                            className= { !isFormFilled ? "disabled:bg-[#EAECEF] bg-[#2D73C8] rounded py-2 px-4 disabled:text-[#89949F] text-white": "bg-[#EAECEF] rounded py-2 px-4" }>Save Changes</button>
                        </div>
                    </form>
                    
                </div>
            </Modal>
            {showNotification && (
                <SuccessNotification 
                    message={notificationMessage}
                    onClose={handleCloseNotification}
                />
            )}
        </div>
        
    )

}
