import React from "react"
import addDataSet from "../../assets/svg/add-data-set.svg"
import grayInfo from "../../assets/svg/gray-info.svg"
import { DataContext } from "../../contexts/Site"
import CatalogDetails from "../../components/aiCompany/catalogDetails"
import Loading from "../../components/Loading"
import { listPlaylistPerCompany } from "../../modified-graphql/queries"
import { generateClient } from "aws-amplify/api"
import { useNavigate } from "react-router-dom"

import DropdownMenuTrainingSet from "../../components/aiCompany/DropdownMenuTrainingSet"

const API = generateClient()

export default function TrainingData() {
    const {licensesUsage, companyId, playlist} = React.useContext(DataContext)
    const navigate = useNavigate()
   

    const tableElement = playlist !== null &&  playlist.map((item) => {
        return (

            <tr className="*:py-2 hover:bg-[#F1F1F1] group" key={item.name}>
                                <td className="pl-[18px]">{item.name}</td>
                                <td>{item.description}</td>
                                <td>{ item.Licenses.items.map(license => license.name).join(', ') }</td>
                                <td>{item.status}</td>
                                <td>{item.musics.items.length}</td>
                                <td className="pr-10 *:opacity-0 group-hover:*:opacity-100 *:transition-all *:duration-150">
                                    <DropdownMenuTrainingSet {...item}/>  
                                    
                                </td>
            </tr>

        )
    })
    return (
        <div className="page-main">
        {
            licensesUsage === null ? (
                <>
                <Loading />
                </>
            ):
            (
                <>
                    
                    <div className="flex font-medium text-[40px] leading-[48px] gap-x-[98px] items-center">
                        <span className="text-[#000000DE]">
                        Training Data
                        </span>
                        <button className="bg-[#2D73C8] rounded text-sm text-white h-9 px-4 flex items-center gap-x-2" onClick={()=> navigate('/fullMetadataCatalog')}>
                        <img src={addDataSet} alt="data set" width="16" height="16" />
                            Create New Training Data Set
                        </button>
                    </div>

                    <div className="mt-14 flex items-center gap-x-6 font-medium text-[#212529] text-2xl leading-7">Accessible Catalogs
                        <img src={grayInfo} alt="gray-info" width="24" height="24" />
                    </div>
                    {/* Catalog details */}
                    {
                        licensesUsage.map((item) => {
                            return (
                                <CatalogDetails key={item.id} {...item}/>
                            )
                        })
                    }
                    {/* Training data set */}
                    <div className="mt-14">
                        <span className="font-medium text-2xl text-[#212529]">
                            My Training Data Sets
                        </span>

                        
                        <table className="mt-6">
                            <thead className="text-left text-[#454545] text-sm font-bold">
                                <tr className="*:py-2 *:capitalize">
                                <th className="w-[270px] pl-[18px]">Name</th>
                                <th className="w-[270px]">Description</th>
                                <th className="w-[150px]">Catalogs</th>
                                <th className="w-[150px]">Status</th>
                                <th className="w-[150px]">Tracks</th>
                                <th></th>
                                </tr>
                            </thead>
                            <tbody className="text-sm">
                                {tableElement}
                            </tbody>
                        </table>
                    </div>
                    

                </>
            )
        }
        </div>
    )
}