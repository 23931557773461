import React from 'react'

// import image
import MagnifierSvg from '../assets/svg/magnifier.svg'
import DownloadSvg from '../assets/svg/download.svg'
import OverlappedCircles from '../assets/svg/overlapped-circles.svg'

import DropdownSelector from '../components/dropdown-select'

export default function InferenceExplorer() {
  return (
    <div className="py-6 px-3">
      <h1 className="text-[#000000DE] font-medium text-[40px] leading-[48px]">
        Generated Tracks
      </h1>

      <div className="mt-9 flex flex-col sm:flex-row sm:items-center gap-y-2">
        <div className="flex">
          <DropdownSelector disabled />
          <div className="lg:w-[800px] relative mr-6">
            <input className="w-full h-[38px] rounded-r border border-solid border-[#89949F] pr-16 outline-none px-5" />
            <button
              className="absolute top-0 right-0 h-[38px] w-12 flex items-center justify-center border-l border-l-[#89949F]"
              style={{ borderLeftStyle: 'solid' }}
            >
              <img src={MagnifierSvg} alt="search" width={16} height={16} />
            </button>
          </div>
        </div>

        <div className="flex items-center ml-auto gap-x-6">
          <button className="h-[38px] bg-[#E7E7E7] rounded leading-6 opacity-40 px-3 flex gap-x-2 items-center min-w-[126px]">
            <img src={DownloadSvg} alt="download" width={16} height={16} />
            Download
          </button>

          <label className="flex gap-x-2 items-center min-w-[120px]">
            Select All
            <input type="checkbox" className="w-4 h-4" />
          </label>
        </div>
      </div>

      <div className='overflow-x-auto'>
        <table className="text-[#304050] w-full">
          <thead style={{ boxShadow: '0px 6px 8px 0px #0000001A' }}>
            <tr className="leading-[30px] *:py-5 *:pl-6 *:first-pl-[18px] *:min-w-[155px] font-bold border-b border-b-solid border-b-[#CED4DA]">
              <td>Track ID</td>
              <td>Track Name</td>
              <td>Licensee</td>
              <td>Model</td>
              <td>Created</td>
              <td>Length</td>
              <td>Attributions</td>
              <td></td>
            </tr>
          </thead>

          {/* <tbody>
          <tr>
          </tr>
        </tbody> */}
        </table>
      </div>

      <div className="my-16 flex justify-center py-3">
        <div className="flex flex-col sm:flex-row items-center gap-x-6 rounded bg-[#F0F6FF] p-6">
          <img src={OverlappedCircles} width={127} height={114} />
          <div className="text-center text-[#304050]">
            <p className="font-extrabold pb-[10px]">
              No Generated Tracks Yet...
            </p>
            <div className="text-sm max-w-[343px]">
              Music generated on the Musical AI platform using your training
              data is saved to your Generated Tracks list here. As tracks are
              created, they will appear in this list where you can view info and
              listen.{' '}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
