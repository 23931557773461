import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import "../style/bootstrap.css"

export default function DropdownMenu ({children, ...props}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const {licenseName, usageSpec, setOpenChangeExpirationDate, setOpenDeactivateAccess, setModalData} = props
  const [position, setPosition] = useState({ top: '100%', left: '0' })

  const navigate = useNavigate()
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const modal  = (action, spec) => {
    if (action === "Change Expiration Date")
    {
      setModalData({
        licenseName,
        ...usageSpec
      })
      setOpenChangeExpirationDate(true) 
    }
    else if (action === "Change Activation") {
      setModalData({
        licenseName,
        ...usageSpec
      })
      setOpenDeactivateAccess(true) 
    }
  }
  const dropDownElement = ["Change Expiration Date", "Change Activation", "History & Reporting"].map((item) => (
      item !== "History & Reporting" ? (<li key={item}> <button onClick={ () => modal(item, usageSpec)} style={{ width: '100%', height: '100%', textAlign: 'left' }} > {item} </button>  </li>) : (<li key={item}> <button onClick={ () => navigate("/reportanalytics")} style={{ width: '100%', height: '100%', textAlign: 'left' }}> {item} </button>  </li> )
  ))

  useEffect(() => {
    if (isOpen) {
      const dropdown = dropdownRef.current
      const rect = dropdown.getBoundingClientRect()
      const spaceAbove = rect.top
      const spaceBelow = window.innerHeight - rect.bottom
      const spaceLeft = rect.left
      const spaceRight = window.innerWidth - rect.right

      let newPosition = { top: '100%', left: '0' } // Default to bottom

      if (spaceBelow < dropdown.offsetHeight && spaceAbove > dropdown.offsetHeight) {
        newPosition.top = `-${dropdown.offsetHeight}px` // Open on top

      }

      if (spaceRight < dropdown.offsetWidth && spaceLeft > dropdown.offsetWidth) {
        newPosition.left = `-${dropdown.offsetWidth}px` // Open on left
      }
      setPosition(newPosition)
    }
  }, [isOpen])
  
  return (
    <div className="dropdown">
      <button onClick={toggleDropdown} className="dropdown-toggle">
        {children}
      </button>
      {isOpen && (
        <div ref={dropdownRef} className="dropdown-menu" style={{ position: 'absolute', top: position.top, left: position.left }}>
            {props.title && (<p> {props.title} </p>)}
          <ul>
            {dropDownElement}
          </ul>
        </div>
      )}
    </div>
  );
};
