/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://nqkm7rcygfcz5cqeu4kxhuim6a.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-west-2:dcda4f45-7e5c-4a44-95f4-33e832a96272",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_1EU6WOpUn",
    "aws_user_pools_web_client_id": "2n40r1diva4ctbg12e4br2t8sf",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "amplifyapp6fa5d4edd7d84cf2adec2f83c1ddfdaa29e38-demo",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
