import React from 'react';
import Modal from './modals/Modal';
import '../style/notification.css' 

const VerifyCodePopup = ({ isOpen, onRequestClose, onVerify }) => {
  const [code, setCode] = React.useState('');

  const handleSubmit = () => {
    onVerify(code);
    onRequestClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onRequestClose}>
        <div className="border shadow-md p-6 bg-white rounded w-[490px]">
            <div className="text-xl font-medium text-[#212529] flex items-center justify-between">
                Enter Verification Code
                <button onClick={onRequestClose}>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19.3516 6.9609L17.9416 5.5509L12.3516 11.1409L6.76156 5.5509L5.35156 6.9609L10.9416 12.5509L5.35156 18.1409L6.76156 19.5509L12.3516 13.9609L17.9416 19.5509L19.3516 18.1409L13.7616 12.5509L19.3516 6.9609Z"
                        fill="black" />
                    </svg>

                </button>
            </div>
            
            <div className="mt-9">
                <p className="font-bold text-sm text-[#454545]">Code</p>
                <input
                    type="text"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    placeholder="Enter code"
                    className="mt-3 px-3 w-[250px] h-9 border border-[#89949F] rounded outline-none"
                />
            </div>

            <div className="mt-20 flex justify-end gap-x-3 text-sm">
            <button className="bg-[#EAECEF] rounded py-2 px-4" onClick={onRequestClose} >Cancel</button>
            <button type="submit"
                className= "bg-[#EAECEF] rounded py-2 px-4" onClick={handleSubmit} >Verify</button>
            </div>
            
        </div>
    </Modal>
    
    
  );
};

export default VerifyCodePopup;