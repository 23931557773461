import React from 'react'
import { useClickAway } from '@uidotdev/usehooks'
import ChevronLineDownSvg from '../assets/svg/chevron-down-line.svg'

export default function DropdownSelect({ disabled = false, options = [], onChange }) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [index, setIndex] = React.useState(0)
  const ref = useClickAway(() => {
    setIsOpen(false)
  })
  // const {onChange, value } = props

  return (
    <div ref={ref} className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        disabled={disabled}
        className="group min-w-[112px] flex items-center gap-x-2 leading-6 py-[6px] px-3 rounded-l border border-solid border-[#FFEA2F] bg-[#FFF9CA] disabled:bg-[#E7E7E7] disabled:border-[#E7E7E7] disabled:text-[#21252990] disabled:cursor-not-allowed"
      >
        {options[index] || 'Prompt'}
        <img
          src={ChevronLineDownSvg}
          alt="chevron down"
          width={16}
          height={16}
          className="group-disabled:opacity-80"
        />
      </button>
      {isOpen && (
        <ul className="absolute z-10 top-12 py-1 w-[340px] bg-white rounded shadow-md">
          {options?.map((option, index) => (
            <li
              onClick={() => {
                setIndex(index)
                setIsOpen(false)
                onChange(option)
              }}
              key={option}
              className="cursor-pointer px-3 leading-10 hover:bg-gray-100 transition-all duration-150"
              // onChange={() => onChange(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
