import React from "react";
import { useNavigate } from "react-router-dom";
import { Hub } from "aws-amplify/utils";

export default function RedirectLogin(){

    const navigate = useNavigate()

    React.useEffect(() => {
        Hub.listen("auth", (data) => {
            console.log(data?.payload?.event)
        if (data?.payload?.event === "signedIn") {
            navigate("/");
        }
        });
    }, []);

    return (
        <>
        </>
    )

}