import React from 'react'

import FileChartSvg from '../assets/svg/file-chart.svg'
import SendReportSvg from '../assets/svg/send-report.svg'

import {DataContext} from "../contexts/Site"
import RedirectLogin from '../components/RedirectLogin'


// import image

export default function ReportAnalytics() {
  const {companyId, companyName, rightSummary} = React.useContext(DataContext)
  return (
    <div className="py-6 px-3">
      
      <h1 className="font-medium text-2xl leading-[30px] text-[#000000DE]">
        Reports & Analytics
      </h1>
      <p className="mt-6 font-medium text-[40px] leading-[48px] text-[#000000DE]">
        {companyName}
      </p>

      <ul className="mt-14 text-[#304050]">
        <li>
          <b className="font-extrabold">Active Licensees: </b>0
        </li>
        <li>
          <b className="font-extrabold">Trained Models: </b>0
        </li>
        <li>
          <b className="font-extrabold">Attributed AI Tracks: </b>0
        </li>
        <li>
          <b className="font-extrabold">Artist Attributions: </b>0
        </li>
      </ul>

      <section
        id="recurring reports"
        className="mt-14 sm:w-[730px] p-6 rounded-[12px] border border-[#5C6670]"
      >
        <div className="flex flex-col sm:flex-row items-center justify-between gap-y-3">
          <h3 className="font-medium text-2xl leading-[30px] text-[#212529]">
            Recurring Reports
          </h3>

          <label className="flex items-center gap-x-2 font-medium text-[#304050]">
            <span className="w-9 h-5 p-[2px] flex justify-end rounded-full bg-[#245B9E]">
              <span className="rounded-full w-4 h-4 bg-white" />
            </span>
            On
          </label>
        </div>

        <div className="mt-6 flex flex-col sm:flex-row items-end justify-between text-[#304050]">
          <div className="report-body">
            <div className="leading-6">
              <b className="font-extrabold">Report Type: </b>Attribution Report
              by Artist
            </div>
            <div className="leading-6">
              <b className="font-extrabold">Frequency: </b>Monthly
            </div>
            <div className="leading-6">
              <b className="font-extrabold">Licensees: </b>
            </div>
            <div className="leading-6">
              <b className="font-extrabold">Recepients: </b>reports@wearemusical.ai
            </div>
          </div>

          <button className="text-sm text-black px-4 py-2 bg-[#FCF1A1] rounded">
            Settings
          </button>
        </div>
      </section>

      <section id="create report" className="mt-20">
        <h2 className="flex items-center gap-x-6 text-[32px] leading-10 font-medium text-[#212529]">
          Create Report
          <img src={FileChartSvg} alt="file chart" width={24} height={24} />
        </h2>

        <p className="mt-4 text-[#304050] max-w-[450px]">
          Reports will be generated and sent to email address as an attachment.
          Please allow up to 2 business days for reports to complete.
        </p>

        <label className="mt-9 w-fit flex flex-col gap-y-2 text-[#304050] font-medium">
          Report Type
          <select className="w-[300px] h-10 border border-[#89949F] rounded px-4 py-2 text-[#808080]">
            <option>Attribution Report by Artists</option>
          </select>
        </label>

        <div className="mt-14">
          <span className="text-[#304050] font-medium">
            AI Company Licenses
          </span>
          <div className="mt-2 *:flex *:items-center *:gap-x-3">
            <label>
              <input
                defaultChecked
                type="checkbox"
                className="w-[18px] h-[18px]"
              />
              Demo AI Company
            </label>
          </div>
        </div>

        <div className="mt-14">
          <p className="mb-2 text-[#304050] font-medium">Timeframe</p>

          <div className="flex items-center gap-x-2">
            <label className="flex items-center gap-x-3">
              <input type="radio" />
              Month
            </label>

            <select className="w-[200px] h-10 border border-[#89949F] rounded px-4 py-2 text-[#808080]">
              <option>December</option>
            </select>
            <select className="w-[144px] h-10 border border-[#89949F] rounded px-4 py-2 text-[#808080]">
              <option>2024</option>
            </select>
          </div>

          <div className="mt-5 flex items-center gap-x-2">
            <label className="flex items-center gap-x-3">
              <input type="radio" />
              Date Range
            </label>
          </div>
        </div>

        <label className="flex flex-col gap-y-2 mt-14">
          <span className="text-[#304050] font-medium">Format</span>
          <select className="w-[300px] h-10 border border-[#89949F] rounded px-4 py-2 text-[#808080]">
            <option>PDF</option>
            <option>Docx</option>
          </select>
        </label>

        <button className="mt-14 py-3 px-4 bg-[#2D73C8] rounded text-white flex w-fit items-center gap-x-2 ">
          <img src={SendReportSvg} alt="send report" width={20} height={20} />
          Send Report
        </button>
      </section>
    </div>
  )
}
