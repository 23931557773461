import React from 'react'

import Site from './contexts/Site'
// Import css
import './style/global.css'
import '@aws-amplify/ui-react/styles.css'

// Import page and jsx

import Header from './components/header'
import Routing from './Routing'

import { withAuthenticator } from '@aws-amplify/ui-react'
import { Router } from 'react-router-dom'
import { LogoLink } from './Routing'

function App() {
  return (
    <Site className="app">
      <Routing />
      {/* <Navbar />
                <main >
                    <Header />
                    <Routing />
                </main> */}
    </Site>
  )
}

const CustomSignInHeader = () => (
  <header className="fixed top-0 left-0 w-full">
    <div className="px-6 py-3 bg-white">
      <a href="http://www.wearemusical.ai">
        <img src={'/musical-ai.svg'} width="200" height="36" />
      </a>
    </div>
    <img
      src="/assets/formly-logo-mark.png"
      width={187}
      height={30}
      className="mt-2 ml-9"
    />
  </header>
)

export default withAuthenticator(App, {
  components: {
    Header: CustomSignInHeader,
  },
})
