import React from "react"


export default function DataSummary(props) {

    const {AvgAttributesTrack, genreBreakdown, SeperateStems, TotalTracks,totalAudioLength } = props
    let genreBreakdownArray = []
    if (genreBreakdown !== undefined ) {
        try {
            genreBreakdownArray = JSON.parse(genreBreakdown).map(genre => {
                const [name, percentage] = genre.split(': ');
                return `${percentage} - ${name}`;
            });
          } catch (error) {
            console.error("Error parsing JSON string:", error);
          }
    }
    
    
    
      return (
        <section className="data-summary">
            <h2>Data Summary</h2>
            <div className="data-summary-container max-sm:flex-col">
            <ul>
                <li>
                <b>Total Audio Length:</b> {totalAudioLength}
                </li>
                <li>
                <b>Total Tracks:</b> {Number(TotalTracks).toLocaleString('en-US')}
                </li>
                <li>
                <b>Avg. Attributes/Track:</b> {AvgAttributesTrack}
                </li>
                <li>
                <b>Separate Stems:</b> {SeperateStems}
                </li>
            </ul>
            <div className="data-summary-chart">
                {/* <div className="data-summary-chart-div"></div> */}
                
                <ul>
                {(genreBreakdown !== undefined ? genreBreakdownArray  : [] ).map((genre, index) => (
                     <li key={index}>{genre}</li>
                    ))}
                </ul>
            </div>
            </div>
      </section>
    )
}
