import React, { useEffect } from 'react'
import Modal from './modals/Modal'
import { DataContext } from '../contexts/Site'
import SuccessNotification from './SuccessNotification'
import { updateLicenseUsage } from '../modified-graphql/mutations'
import { generateClient } from 'aws-amplify/api'
import '../style/notification.css'
import { Button } from '@nextui-org/button'

const API = generateClient()

export default function DeactivateAccess(props) {
  const [modal, setModal] = React.useState('warning')

  const { modalData, openDeactivateAccess, setOpenDeactivateAccess } = props
  const { setUpdate } = React.useContext(DataContext)
  const [showNotification, setShowNotification] = React.useState(false)

  const closeModal = () => {
    setOpenDeactivateAccess(false)
    setModal('warning')
  }

  const handleShowNotification = () => {
    setShowNotification(true)
  }

  const handleCloseNotification = () => {
    setShowNotification(false)
  }

  // Form management

  async function handleSubmit(event) {
    event.preventDefault()

    setModal('confirmation')
    // const input = {
    //   id: modalData.id,
    //   status: 'DEACTIVATED',
    // }
    // await API.graphql({
    //   query: updateLicenseUsage,
    //   variables: { input },
    // })
    // setUpdate((prevValue) => prevValue + 1)
    // handleShowNotification()
    // handleCancel()
  }

  const handleCancel = () => {
    setOpenDeactivateAccess(false)
  }

  const getXIcon = () => {
    return (
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.3516 7.1307L17.9416 5.7207L12.3516 11.3107L6.76156 5.7207L5.35156 7.1307L10.9416 12.7207L5.35156 18.3107L6.76156 19.7207L12.3516 14.1307L17.9416 19.7207L19.3516 18.3107L13.7616 12.7207L19.3516 7.1307Z"
          fill="black"
        />
      </svg>
    )
  }

  // console.log(modalData)
  // handle when the submit button become available or not
  return (
    <>
      {modal === 'warning' && (
        <Modal isOpen={openDeactivateAccess} onClose={closeModal}>
          <div className="expiration-modal-content">
            <button onClick={closeModal} className="absolute top-4 right-4">
              {getXIcon()}
            </button>
            <div className="expiration-modal-header">
              <span className="expiration-icon">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.9416 8.3007L10.3516 14.8907L6.76156 11.3107L5.35156 12.7207L10.3516 17.7207L18.3516 9.7207L16.9416 8.3007ZM12.3516 2.7207C6.83156 2.7207 2.35156 7.2007 2.35156 12.7207C2.35156 18.2407 6.83156 22.7207 12.3516 22.7207C17.8716 22.7207 22.3516 18.2407 22.3516 12.7207C22.3516 7.2007 17.8716 2.7207 12.3516 2.7207ZM12.3516 20.7207C7.93156 20.7207 4.35156 17.1407 4.35156 12.7207C4.35156 8.3007 7.93156 4.7207 12.3516 4.7207C16.7716 4.7207 20.3516 8.3007 20.3516 12.7207C20.3516 17.1407 16.7716 20.7207 12.3516 20.7207Z"
                    fill="#DC3545"
                  />
                </svg>
              </span>
              <h2 className="!text-[#DC3545] !text-base">
                Deactivate (Revoke) Access
              </h2>
            </div>
            <div className="expiration-modal-body !text-sm !mb-9">
              <p>
                Are you sure you would like to deactivate access to your catalog{' '}
                <strong>{`${
                  modalData !== '' && modalData.licenseName
                }`}</strong>{' '}
                for{' '}
                <strong>
                  {' '}
                  {`${
                    modalData !== '' && modalData.borrowingCompany.Name
                  }`}{' '}
                </strong>
                ?
              </p>
              <br />
              <p>
                This will not affect training already in progress and the
                Licensee will be notified.
              </p>
            </div>
            <div className="expiration-modal-footer">
              <button
                className="expiration-cancel-button !text-sm"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="expiration-deactivate-button !text-sm"
                onClick={handleSubmit}
              >
                Deactivate
              </button>
            </div>
          </div>
        </Modal>
      )}

      {modal === 'confirmation' && (
        <Modal isOpen={openDeactivateAccess} onClick={closeModal}>
          <div className="bg-white rounded-[10px] shadow-[0_4px_6px_#0000002D] p-3 border border-[#00000033]">
            <div className="flex p-2 gap-x-3 items-center text-[#304050]">
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.8322 8.21281L10.2422 14.8028L6.65219 11.2228L5.24219 12.6328L10.2422 17.6328L18.2422 9.63281L16.8322 8.21281ZM12.2422 2.63281C6.72219 2.63281 2.24219 7.11281 2.24219 12.6328C2.24219 18.1528 6.72219 22.6328 12.2422 22.6328C17.7622 22.6328 22.2422 18.1528 22.2422 12.6328C22.2422 7.11281 17.7622 2.63281 12.2422 2.63281ZM12.2422 20.6328C7.82219 20.6328 4.24219 17.0528 4.24219 12.6328C4.24219 8.21281 7.82219 4.63281 12.2422 4.63281C16.6622 4.63281 20.2422 8.21281 20.2422 12.6328C20.2422 17.0528 16.6622 20.6328 12.2422 20.6328Z"
                  fill="#2D73C8"
                />
              </svg>
              Request Sent
              <button className="ml-auto" onClick={closeModal}>
                {getXIcon()}
              </button>
            </div>
            <p className="mt-5 max-w-[340px] mx-2 text-sm">
              Our support team has received your request to Deactivate Access
              for licensee, Udio. You will receive a confirmation message when
              the deactivation goes into effect.
              <br />
              <br />
              This will not affect training already in progress and the licensee
              will be notified.
            </p>

            <Button
              onClick={closeModal}
              color="primary"
              radius="sm"
              className="mt-9 ml-auto flex"
            >
              Close
            </Button>
          </div>
        </Modal>
      )}
      {showNotification && (
        <SuccessNotification
          message={`Access Revoqued`}
          onClose={handleCloseNotification}
        />
      )}
    </>
  )
}
