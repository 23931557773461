import React from "react"
import { useParams } from "react-router-dom"


export default function TrainingSetDetails(){
    
    const {id} = useParams()
    return (

        <div>
            
            Training Data from {id}
        </div>
    )
}