/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRightsSummary = /* GraphQL */ `
  mutation CreateRightsSummary(
    $input: CreateRightsSummaryInput!
    $condition: ModelRightsSummaryConditionInput
  ) {
    createRightsSummary(input: $input, condition: $condition) {
      id
      LastCatalogUpdate
      Company {
        id
        Name
        createdAt
        updatedAt
        companyRightsSummaryId
        owner
        __typename
      }
      TotalTracks
      totalAudioLength
      AvgAttributesTrack
      SeperateStems
      genreBreakdown
      createdAt
      updatedAt
      rightsSummaryCompanyId
      owner
      __typename
    }
  }
`;
export const updateRightsSummary = /* GraphQL */ `
  mutation UpdateRightsSummary(
    $input: UpdateRightsSummaryInput!
    $condition: ModelRightsSummaryConditionInput
  ) {
    updateRightsSummary(input: $input, condition: $condition) {
      id
      LastCatalogUpdate
      Company {
        id
        Name
        createdAt
        updatedAt
        companyRightsSummaryId
        owner
        __typename
      }
      TotalTracks
      totalAudioLength
      AvgAttributesTrack
      SeperateStems
      genreBreakdown
      createdAt
      updatedAt
      rightsSummaryCompanyId
      owner
      __typename
    }
  }
`;
export const deleteRightsSummary = /* GraphQL */ `
  mutation DeleteRightsSummary(
    $input: DeleteRightsSummaryInput!
    $condition: ModelRightsSummaryConditionInput
  ) {
    deleteRightsSummary(input: $input, condition: $condition) {
      id
      LastCatalogUpdate
      Company {
        id
        Name
        createdAt
        updatedAt
        companyRightsSummaryId
        owner
        __typename
      }
      TotalTracks
      totalAudioLength
      AvgAttributesTrack
      SeperateStems
      genreBreakdown
      createdAt
      updatedAt
      rightsSummaryCompanyId
      owner
      __typename
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      Name
      RightsSummary {
        id
        LastCatalogUpdate
        TotalTracks
        totalAudioLength
        AvgAttributesTrack
        SeperateStems
        genreBreakdown
        createdAt
        updatedAt
        rightsSummaryCompanyId
        owner
        __typename
      }
      licenses {
        nextToken
        __typename
      }
      licenseUsages {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyRightsSummaryId
      owner
      __typename
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      Name
      RightsSummary {
        id
        LastCatalogUpdate
        TotalTracks
        totalAudioLength
        AvgAttributesTrack
        SeperateStems
        genreBreakdown
        createdAt
        updatedAt
        rightsSummaryCompanyId
        owner
        __typename
      }
      licenses {
        nextToken
        __typename
      }
      licenseUsages {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyRightsSummaryId
      owner
      __typename
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      Name
      RightsSummary {
        id
        LastCatalogUpdate
        TotalTracks
        totalAudioLength
        AvgAttributesTrack
        SeperateStems
        genreBreakdown
        createdAt
        updatedAt
        rightsSummaryCompanyId
        owner
        __typename
      }
      licenses {
        nextToken
        __typename
      }
      licenseUsages {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyRightsSummaryId
      owner
      __typename
    }
  }
`;
export const createLicense = /* GraphQL */ `
  mutation CreateLicense(
    $input: CreateLicenseInput!
    $condition: ModelLicenseConditionInput
  ) {
    createLicense(input: $input, condition: $condition) {
      id
      name
      company {
        id
        Name
        createdAt
        updatedAt
        companyRightsSummaryId
        owner
        __typename
      }
      usages {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyLicensesId
      owner
      __typename
    }
  }
`;
export const updateLicense = /* GraphQL */ `
  mutation UpdateLicense(
    $input: UpdateLicenseInput!
    $condition: ModelLicenseConditionInput
  ) {
    updateLicense(input: $input, condition: $condition) {
      id
      name
      company {
        id
        Name
        createdAt
        updatedAt
        companyRightsSummaryId
        owner
        __typename
      }
      usages {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyLicensesId
      owner
      __typename
    }
  }
`;
export const deleteLicense = /* GraphQL */ `
  mutation DeleteLicense(
    $input: DeleteLicenseInput!
    $condition: ModelLicenseConditionInput
  ) {
    deleteLicense(input: $input, condition: $condition) {
      id
      name
      company {
        id
        Name
        createdAt
        updatedAt
        companyRightsSummaryId
        owner
        __typename
      }
      usages {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyLicensesId
      owner
      __typename
    }
  }
`;
export const createLicenseUsage = /* GraphQL */ `
  mutation CreateLicenseUsage(
    $input: CreateLicenseUsageInput!
    $condition: ModelLicenseUsageConditionInput
  ) {
    createLicenseUsage(input: $input, condition: $condition) {
      id
      license {
        id
        name
        createdAt
        updatedAt
        companyLicensesId
        owner
        __typename
      }
      borrowingCompany {
        id
        Name
        createdAt
        updatedAt
        companyRightsSummaryId
        owner
        __typename
      }
      startDate
      endDate
      createdAt
      updatedAt
      companyLicenseUsagesId
      licenseUsagesId
      owner
      attributions
      __typename
    }
  }
`;
export const updateLicenseUsage = /* GraphQL */ `
  mutation UpdateLicenseUsage(
    $input: UpdateLicenseUsageInput!
    $condition: ModelLicenseUsageConditionInput
  ) {
    updateLicenseUsage(input: $input, condition: $condition) {
      id
      license {
        id
        name
        createdAt
        updatedAt
        companyLicensesId
        owner
        __typename
      }
      borrowingCompany {
        id
        Name
        createdAt
        updatedAt
        companyRightsSummaryId
        owner
        __typename
      }
      startDate
      endDate
      createdAt
      updatedAt
      companyLicenseUsagesId
      licenseUsagesId
      owner
      __typename
    }
  }
`;
export const deleteLicenseUsage = /* GraphQL */ `
  mutation DeleteLicenseUsage(
    $input: DeleteLicenseUsageInput!
    $condition: ModelLicenseUsageConditionInput
  ) {
    deleteLicenseUsage(input: $input, condition: $condition) {
      id
      license {
        id
        name
        createdAt
        updatedAt
        companyLicensesId
        owner
        __typename
      }
      borrowingCompany {
        id
        Name
        createdAt
        updatedAt
        companyRightsSummaryId
        owner
        __typename
      }
      startDate
      endDate
      createdAt
      updatedAt
      companyLicenseUsagesId
      licenseUsagesId
      owner
      __typename
    }
  }
`;
export const createNotePriv = /* GraphQL */ `
  mutation CreateNotePriv(
    $input: CreateNotePrivInput!
    $condition: ModelNotePrivConditionInput
  ) {
    createNotePriv(input: $input, condition: $condition) {
      id
      name
      description
      image
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateNotePriv = /* GraphQL */ `
  mutation UpdateNotePriv(
    $input: UpdateNotePrivInput!
    $condition: ModelNotePrivConditionInput
  ) {
    updateNotePriv(input: $input, condition: $condition) {
      id
      name
      description
      image
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteNotePriv = /* GraphQL */ `
  mutation DeleteNotePriv(
    $input: DeleteNotePrivInput!
    $condition: ModelNotePrivConditionInput
  ) {
    deleteNotePriv(input: $input, condition: $condition) {
      id
      name
      description
      image
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
      name
      description
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote(
    $input: UpdateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    updateNote(input: $input, condition: $condition) {
      id
      name
      description
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $input: DeleteNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    deleteNote(input: $input, condition: $condition) {
      id
      name
      description
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
