import React from 'react'
import { DataContext } from '../contexts/Site'
import filechart from '../assets/svg/file-chart.svg'
import dotdotdot from '../assets/svg/dotdotdot.svg'
import addinrect from '../assets/svg/add-in-rect.svg'
import DropdownMenu from './dropdownMenu'
import NewLicencee from './NewLicensee'
import DeactivateAccess from './DeactivateAccess'
import ChangeExpirationDate from './ChangeExpirationDate'

import { useNavigate } from 'react-router-dom'

import '../style/modal.css'
// import "../style/modal-ui.style.css"

export default function Licensee(props) {
  const { borrowingCompanies } = React.useContext(DataContext)
  const [openChangeExpirationDate, setOpenChangeExpirationDate] =
    React.useState(false)
  const [openDeactivateAccess, setOpenDeactivateAccess] = React.useState(false)
  const [modalData, setModalData] = React.useState('')
  const { expand } = props

  const navigate = useNavigate()

  function formatDate(dateString) {
    const date = new Date(dateString)

    const month = date.getUTCMonth() + 1
    const day = date.getUTCDate()
    const year = date.getUTCFullYear().toString().slice(-2)

    return `${month}/${day}/${year}`
  }

  const trElements =
    borrowingCompanies === null
      ? []
      : borrowingCompanies.map((item) =>
          item.usages.items.map((nestedItem) => (
            <tr key={`${nestedItem.id}`}>
              {/*}<td>{item.name}</td>{*/}
              <td>{nestedItem.borrowingCompany.Name}</td>
              <td>
                {formatDate(nestedItem.startDate)} -{' '}
                {formatDate(nestedItem.endDate)}
              </td>
              <td>{nestedItem.status}</td>
              <td>{nestedItem.models}</td>
              <td>{nestedItem.attributions}</td>
              {!expand && (
                <td className='min-w-[160px]'>
                  <button>
                    <img
                      src={filechart}
                      width="24"
                      height="24"
                      draggable="false"
                    />
                  </button>
                  <DropdownMenu
                    licenseName={item.name}
                    usageSpec={nestedItem}
                    setOpenChangeExpirationDate={setOpenChangeExpirationDate}
                    setOpenDeactivateAccess={setOpenDeactivateAccess}
                    setModalData={setModalData}
                  >
                    <img
                      src={dotdotdot}
                      width="44"
                      height="24"
                      draggable="false"
                    />
                  </DropdownMenu>
                </td>
              )}
            </tr>
        ))
    )

    
    return (
        <section className="licensee">
            <div className="heading-with-button">
            <h2>Licensees</h2>
            
            { expand ? (
                <button className="yellow-button" onClick={() => navigate("/managelicenses") }>
                    Manage Access
                </button>
            ) : (
                <NewLicencee/>
            )}
            </div>
            <table className="data-table">
            <thead>
                <tr>
                {/*}<th>License Name</th>{*/}
                <th>Company Name</th>
                <th>Term</th>
                <th>Status</th>
                <th>Models</th>
                <th>Attributions</th>
                {!expand && (
                <th></th>
                )}
                </tr>
            </thead>
            <tbody>
                {trElements}
            </tbody>
            </table>
            <ChangeExpirationDate modalData={modalData} openChangeExpirationDate={openChangeExpirationDate} setOpenChangeExpirationDate={setOpenChangeExpirationDate} />
            <DeactivateAccess modalData={modalData} openDeactivateAccess={openDeactivateAccess} setOpenDeactivateAccess={setOpenDeactivateAccess} />
        </section>
    )
}