import React from 'react'
import ContactSupport from './ContactSupport'


export default function DashboardResume(props) {
    const {LastCatalogUpdate, TotalTracks, companyName} = props

  return (


        <section>
          <div className="flex justify-between items-center">
            <h1 className="w-fit font-medium text-[#000000DE] text-[42px] leading-[60px]">
              {companyName}
            </h1>
            <ContactSupport />
            
          </div>

          <div className="grid grid-cols-4 mt-9">
            <InfoItem content={Number(TotalTracks).toLocaleString('en-US')} title={'Total Tracks'} />
            <InfoItem content={'0'} title={'Tracks Generated'} />
            <InfoItem content={'0'} title={'Trained Models'} />
            <InfoItem content={'0'} title={'Active Licensees'} />
          </div>
          
        </section>

    )
}

function InfoItem({ title, content }) {
  return (
    <div className="text-base text-[#304050]">
      <p className="text-[#212529] font-bold">{title}</p>
      <span className="">{content}</span>
    </div>
  )
}
