import React from "react"
import { useNavigate } from "react-router-dom";
import {formatDate, calculateTotatAudioLength} from "../../utils";




export default function CatalogDetails(props){

    const {license, endDate, attributions, status, id} = props
    const navigate = useNavigate()
    
    const goToPage = (page, id) => {
        navigate(`/${page}/${id}`)
    }
    
    

    return (
        <div className="mt-8 max-w-[840px] rounded-xl border border-[#5C6670] p-6 text-[#212529]">
            <div className="flex items-end justify-between">
            <h2 className="text-3xl font-semibold text-[#212529]">{license.name} from {license.company.Name}</h2>
            <p className="text-[#304050]">
                <b className="text-base font-extrabold">Expires: </b>
                {formatDate(endDate)}
            </p>
            </div>

            <p className="mt-6 text-base">This is a license named {license.name} from the company {license.company.Name} and the status {status}. </p>

            <h3 className="my-6 text-2xl text-[#212529] font-medium">Data Summary</h3>
            <div className="flex gap-x-[70px] text-[#304050]">
            <div>
                <p>
                <b className="font-extrabold">Total Audio Length: </b>{license.Metadata.totalAudioLength}
                </p>
                <p>
                <b className="font-extrabold">Total Tracks: {license.musics.items.length}</b>
                </p>
                <p><b className="font-extrabold">Avg. Attributes/Track: </b> {license.Metadata.AvgAttributesTrack} </p>
                <p><b className="font-extrabold">Separate Stems: </b>{license.Metadata.SeperateStems}</p>
            </div>
            </div>

            <div className="flex justify-end gap-x-6">
            <button className="h-10 bg-[#FCF1A1] py-2 px-4 rounded" onClick={() => goToPage("catalogInfo", id)}>
                Catalog Details
            </button>
            <button className="h-10 bg-[#FCF1A1] py-2 px-4 rounded" onClick={() => goToPage("metadataOverview", id)}>
                Metadata Overview
            </button>
            <button className="h-10 bg-[#FCF1A1] py-2 px-4 rounded" onClick={() => goToPage("fullMetadataCatalog", id)}>
                Full Catalog & Metadata
            </button>
            </div>
      </div>

    )
}