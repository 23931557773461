import React from "react"
import { DataContext } from "../contexts/Site"
import filechart from "../assets/svg/file-chart.svg"
import dotdotdot from "../assets/svg/dotdotdot.svg"
import addinrect from "../assets/svg/add-in-rect.svg"
import DropdownMenu from "./dropdownMenu"
import CatalogAccessSvg from '../assets/svg/catalog-access.svg';

import { useNavigate } from 'react-router-dom';

import { Link  } from 'react-router-dom'

import "../style/modal.css"
// import "../style/modal-ui.style.css"


export default function Licensee(props) {

    const {borrowingCompanies} = React.useContext(DataContext)
    const [modalData, setModalData] = React.useState('')
    const {expand} = props

    const navigate = useNavigate();
    
    function formatDate(dateString) {
        
        const date = new Date(dateString);
      
        
        const month = date.getUTCMonth() + 1;
        const day = date.getUTCDate();
        const year = date.getUTCFullYear().toString().slice(-2);
        
        return `${month}/${day}/${year}`;
      }
    
    
    
    const trElements = borrowingCompanies === null ? [] : borrowingCompanies.map((item) => (
        item.usages.items.map((nestedItem) => (
            <tr className="*:pl-3 *:leading-6 *:py-0" key={`${nestedItem.id}`}>
                {/*}<td>{item.name}</td>{*/}
                <td>{nestedItem.borrowingCompany.Name}</td>
                <td>{formatDate(nestedItem.startDate)} - {formatDate(nestedItem.endDate)}</td>
                <td>{nestedItem.models}</td>
                <td>{nestedItem.attributions}</td>
            </tr>            
        ))
    ))

    
    return (
        <section className="mt-20">
          <Card>
            <h2 className="flex items-center flex-wrap gap-y-2 gap-x-4">
              <img
                src={CatalogAccessSvg}
                alt="catalog access"
                width={32}
                height={32}
              />
              <span className="text-2xl sm:text-[32px] leading-10 text-[#304050] font-medium">
                Catalog Access
              </span>
              <Link to="/managelicenses" className='sm:ml-auto'> 
                <button className="bg-[#FCF1A1] h-8 px-3 text-sm rounded">
                  Manage Access
                </button>
              </Link>
            </h2>

            <table className="mt-6 text-[#454545]">
              <thead>
                <tr className="*:pl-3 font-bold text-base leading-6">
                  {/*}<td className="w-[237px]">Name</td>{*/}
                  <td className="w-[237px]">Company</td>
                  <td className="w-[237px]">Term</td>
                  <td className="w-[90px]">Models</td>
                  <td className="w-[140px]">Attributions</td>
                </tr>
              </thead>
              <tbody className="mt-2">
                {trElements}
                {/*
                <tr className="*:pl-3 *:leading-6 *:py-0">
                  <td>Company 1</td>
                  <td>1/7/24-1/725</td>
                  <td>2</td>
                  <td>6,603</td>
                </tr>
                <tr className="*:pl-3 *:leading-6 *:py-0">
                  <td>Company 2</td>
                  <td>1/7/24-1/725</td>
                  <td>1</td>
                  <td>12,445</td>
                </tr>
                <tr className="*:pl-3 *:leading-6 *:py-0">
                  <td>Company 3</td>
                  <td>1/7/24-1/725</td>
                  <td>4</td>
                  <td>8,289</td>
                </tr>
                <tr className="*:pl-3 *:leading-6 *:py-0">
                  <td>Company 4</td>
                  <td>1/7/24-1/725</td>
                  <td>5</td>
                  <td>30,515</td>
                </tr>
                */}
              </tbody>
            </table>
          </Card>
        </section>

    )
}

export function Card({ children }) {
  return (
    <div className="shadow-[0px_8px_8px_0px_#0000001A] bg-white rounded-[12px] py-6 px-4 sm:px-6">
      {children}
    </div>
  )
}
