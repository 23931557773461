import React from "react"
import { listCompanies, getCompanyLicensee, listLicenseUsages, listCompanyLicenses, listPlaylistPerCompany } from "../modified-graphql/queries";


import { generateClient } from "aws-amplify/api"
import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import { useAuthenticator } from '@aws-amplify/ui-react';


const API = generateClient()
const DataContext = React.createContext()

export { DataContext }
export default function Site({children, ...rest}) {
    const [rightSummary, setRightSummary] = React.useState(null)
    const [companyName, setCompanyName] = React.useState(null)
    const [companyId, setCompanyId] = React.useState(null)
    const [borrowingCompanies, setBorrowingCompanies] = React.useState(null)
    const [companyType, setCompanyType] = React.useState(null)
    const [licensesUsage, setLicensesUsage] = React.useState(null)
    const [playlist, setPlaylist] = React.useState(null)
    const [update, setUpdate] = React.useState(null)
    const [authenticated, setAuthenticated] = React.useState("signOut")



    const { route } = useAuthenticator(context => [context.route]);
    
    React.useEffect(() => {
        setAuthenticated(route)
    }, [route])

    console.log(route)

    React.useEffect(() => {
        fetchSummary()
    }, [update])
    
    async function fetchSummary(){
        try {
            const session = await fetchAuthSession();
            /*
                Make sure that the user belongs to a group
            */
            
            const group = session.tokens.idToken.payload["cognito:groups"] === undefined ? undefined : session.tokens.idToken.payload["cognito:groups"][0]
            if (group === undefined) {
                signOut()
            }
            else {
                let apiData = await API.graphql({query: listCompanies, variables: {filter: {Name:  { eq: group }}}})
                const summaryInformation = apiData.data.listCompanies.items.length > 0 ? apiData.data.listCompanies.items[0] : null
                if (summaryInformation !== null) {
                    const {id: companyId, type: companyType, companyRightsSummaryId} = summaryInformation
                    setCompanyType(companyType)
                    setCompanyId(companyId)
                    setCompanyName(group)
                    if(companyType === "RightHolder") {
                        setRightSummary(summaryInformation.RightsSummary)
                        console.log(companyType)
                        apiData = await API.graphql({query: getCompanyLicensee, variables: {companyID: companyId}})
                        console.log(apiData.data.listLicenses.items)
                        // Get all the license of this company
                        setBorrowingCompanies(apiData.data.listLicenses.items)
                    } else if (companyType === "AI"){
                        console.log(companyId)
                        // List all the licenses that belongs are assigned to a company. 
                        apiData = await API.graphql({
                            query: listCompanyLicenses,
                            variables: {
                                filter: {companyLicenseUsagesId: { eq: companyId} }
                                }
                            })
                        setLicensesUsage(apiData.data.listLicenseUsages.items)

                        // Get the playlist
                        apiData = await API.graphql({query: listPlaylistPerCompany, variables: {filter: {companyPlaylistId:  { eq: companyId }}}})
                        setPlaylist(apiData.data.listPlaylists.items)
                        
                    
                }  else {
                    signOut()
                }
                }
                else {
                    signOut()
                }
            }
            
        } catch (err) {
            console.log(err)
        }
        
        
        
        
        
        
    }
    
    return (
        <DataContext.Provider value={{rightSummary, companyName, borrowingCompanies, companyId, licensesUsage, companyType, playlist, setUpdate,authenticated}}>
            <div {...rest}>
                {children}
            </div>
        </DataContext.Provider>
    )


}
