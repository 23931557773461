import React from "react"
import { useParams, useNavigate } from "react-router-dom"
import { DataContext } from "../../contexts/Site"
import Loading from "../../components/Loading"
import { calculateTotatAudioLength, formatDate } from "../../utils"
import dotdot from "../../assets/svg/dotdotdot.svg"
import DropdownMenuTrainingSet from "../../components/aiCompany/DropdownMenuTrainingSet"

import { listModelPerPlaylist } from "../../modified-graphql/queries"

import { generateClient } from "aws-amplify/api"
const API = generateClient()

export default function CatalogInfo(){

    const {id} = useParams()
    const {licensesUsage, playlist} = React.useContext(DataContext)
    const [catalog, setCatalog] = React.useState(null)
    const [relatedPlaylist, setRelatedPlaylist] = React.useState([])
    const [relatedModel, setRelatedModel] = React.useState([])

    const navigate = useNavigate()
    
    
    function getCatalogInfo(){
        if (licensesUsage !== null ){
            for (const usage of licensesUsage) {
                if (usage.id === id) {
                    setCatalog(usage)
                }
            }
        }
    }
    React.useEffect(() => {
        getCatalogInfo()
    }, [licensesUsage])

    React.useEffect(() => {
        console.log(playlist)
        console.log(catalog)
        if (playlist !== null && catalog !== null){
            setRelatedPlaylist(getLinkedPlaylist())
        }
       
    }, [catalog, playlist] )

    function getLinkedPlaylist(){
        return playlist.filter((item) => {
            for(const license of item.Licenses.items) {
                if (catalog.license.name === license.name){
                    return item
                }
            }
        })
    }

    async function getLinkedModel() {
        // Get all the model associated to the play in the associated training set.
        let apiData
        let count = 0
        for (const playlist of relatedPlaylist){
            apiData = await API.graphql({query: listModelPerPlaylist, variables: {playlistModelsId: playlist.id}})
            if (count === 0){
                setRelatedModel(apiData.data.listModelPlaylists.items)
                count = 1 
            }
            else {
                setRelatedModel((prevValue) => [...prevValue, apiData.data.listModelPlaylists.items ])
            }
            
        }
    }

    React.useEffect(() => {
        getLinkedModel()
    }, [relatedPlaylist] )

    const tablePlaylistElement = relatedPlaylist.map((item) => {
        return (

            <tr className="*:py-2 hover:bg-[#F1F1F1] group" key={item.name}>
                                <td className="pl-[18px]">{item.name}</td>
                                <td>{item.description}</td>
                                <td>{ item.Licenses.items.map(license => license.name).join(', ') }</td>
                                <td>{item.status}</td>
                                <td>{item.musics.items.length}</td>
                                <td className="pr-10 *:opacity-0 group-hover:*:opacity-100 *:transition-all *:duration-150"> <DropdownMenuTrainingSet {...item}/>  </td>
            </tr>

        )
    })

    const tableModelElement = relatedModel.map((item) => {
        return (
            <tr className="*:py-2 hover:bg-[#F1F1F1] group" key={item.model.name}>
                <td className="pl-[18px]">{item.model.name}</td>
                <td>{item.model.Status}</td>
                <td>{formatDate(item.model.LastTrained)}</td>
                <td>{item.model.generatedTracks.items.length}</td>
                <td className="pr-10 *:opacity-0 group-hover:*:opacity-100 *:transition-all *:duration-150"><button
                    className="w-[44px] h-6"><img src={dotdot} /></button></td>
            </tr>
        )
    })
 

    return (
        <div className="page-main">
            {
            catalog === null ? (
                <>
                    <Loading/>
                </>
                
            ) : (
                <>
                    
                    <h1 className="font-medium text-2xl text-[#000000DE]">Catalog Details</h1>
                    <h2 className="mt-6 text-[40px] leading-[48px] font-medium text-[#000000DE]">{`${catalog.license.name} from ${catalog.license.company.Name}`}</h2>
                    <div className="mt-4">
                        <div className="flex gap-x-8 text-[#304050]">
                        <span><b className="font-bold">Last Catalog Update: </b>2/3/24</span>
                        <span><b className="font-bold">Total Tracks: </b>{catalog.license.musics.items.length}</span>
                        </div>
                        <div className="flex gap-x-6 mt-4">
                        <button className="px-4 py-2 text-sm bg-[#FCF1A1] rounded" onClick={() => navigate(`/fullMetadataCatalog/${id}`)}>Full Catalog & Metadata</button>
                        <button className="px-4 py-2 text-sm bg-[#FCF1A1] rounded"onClick={() => navigate(`/inferences/${id}`)}>My Inferences</button>
                        <button className="px-4 py-2 text-sm bg-[#FCF1A1] rounded" onClick={() => navigate(`/attributionAndReporting/${id}`)}>Attributions & Reporting</button>
                        </div>
                    </div>

                    <div className="mt-14">
                        <h2 className="font-medium text-2xl text-[#212529]">Data Summary</h2>
                        <div className="mt-6 text-[#304050]">
                        <div><b className="font-extrabold">Total Audio Length:</b> {calculateTotatAudioLength(catalog.license.musics.items)}</div>
                        <div><b className="font-extrabold">Total Trakcs:</b> {catalog.license.musics.items.lenght}</div>
                        <div><b className="font-extrabold">Avg. Attributes/Track:</b> {catalog.attributions}</div>
                        <div><b className="font-extrabold">Separate Stems:</b> No</div>
                        <button className="mt-9 px-4 py-2 text-sm bg-[#FCF1A1] rounded">View Full Overview</button>
                        </div>
                    </div>

                    <div className="mt-14">
                        <h2 className="font-medium text-2xl text-[#212529]">License History</h2>
                        <div className="mt-4 text-[#304050]"><b className="font-extrabold">{
                            catalog.status.toLowerCase() === "expired" ? "Expired" : "Expires"
                        }: </b>{formatDate(catalog.endDate)}</div>

                        <ul className="mt-4 text-sm *:px-4 *:py-2 *:flex *:gap-x-6 text-[#454545]">
                        {
                            catalog.status.toLowerCase() === "expired" && (
                                <li>
                                    <span className="w-20 block">{formatDate(catalog.endDate)}</span>
                                    <span>Expired</span>
                                </li>
                            )
                        }
                        <li>
                            <span className="w-20 block">{formatDate(catalog.startDate)}</span>
                            <span>Activated</span>
                        </li>
        
                        </ul>
                    </div>

                    <div className="mt-14">
                        <h2 className="font-medium text-2xl text-[#212529]">Associated Training Sets</h2>
                        <table className="mt-6 text-sm">
                        <thead className="text-left text-[#454545] font-bold">
                            <tr className="*:py-2 *:capitalize">
                            <th className="w-[270px] pl-[18px]">Name</th>
                            <th className="w-[270px]">Description</th>
                            <th className="w-[150px]">Catalogs</th>
                            <th className="w-[150px]">Status</th>
                            <th className="w-[150px]">Tracks</th>
                            <th className="w-[150px]"></th>
                            </tr>
                        </thead>
                        <tbody className="text-[#454545]">
                            {tablePlaylistElement}
                        </tbody>
                        </table>
                    </div>

                    <div className="mt-14">
                        <h2 className="font-medium text-2xl text-[#212529]">Associated Models</h2>
                        <table className="mt-6 text-sm text-[#454545]">
                        <thead className="text-left font-bold">
                            <tr className="*:py-2 *:capitalize">
                            <th className="w-[270px] pl-[18px]">Name</th>
                            <th className="w-[270px]">Status</th>
                            <th className="w-[150px]">Trained</th>
                            <th className="w-[150px]">Inferences</th>
                            <th className="w-[150px]"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableModelElement}
                        </tbody>
                        </table>
                    </div>
                </>
            )
        }
        </div>
        
    
    )

}