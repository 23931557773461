export function formatDate(dateString) {
        
    const date = new Date(dateString);
  
    
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const year = date.getUTCFullYear().toString().slice(-2);
    
    return `${month}/${day}/${year}`;
}

export function calculateTotatAudioLength(lenght){
    let totalSecond = 0
    function converToSecond(time) {
        const [minutes, seconds] = time.split(':').map(Number)
        return (minutes * 60 ) + seconds
    }
    function formatTime(seconds) {
        const days = Math.floor(seconds / (24 * 3600))
        seconds %= 24 * 3600
        const hours = Math.floor(seconds / 3600)
        seconds %= 3600
        const minutes = Math.floor(seconds / 60)
        seconds %= 60
        
        const dayStr = days > 0 ? `${days}d` : ''
        const hourStr = hours > 0 ? `${hours}h` : ''
        const minuteStr = minutes > 0 ? `${minutes}m` : ''
        const secondStr = seconds > 0 ? `${seconds}s` : ''
        return [dayStr, hourStr, minuteStr, secondStr].filter(Boolean).join(' ')
    }

    
    for (const item of lenght) {

        totalSecond += converToSecond(item.music.length)
    }

    return formatTime(totalSecond)
    
}

export function getCatalogInfo(licensesUsage, id, setCatalog){
    if (licensesUsage !== null ){
        for (const usage of licensesUsage) {
            if (usage.id === id) {
                setCatalog(usage)
            }
        }
    }
}

export function getCurrentAWSDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };


export function isInThePast(dateTo) {
    const inputDate = new Date(dateTo)
    const currentDate = new Date()

    currentDate.setHours(0, 0, 0, 0)
    if (inputDate > currentDate) {
        return false
    }
    else{
        return true
    }
}

export function formatDatePicketDate(dateObject){
    const {day, month, year} = dateObject 
    return `${month}-${day}-${year}`;
}