export default function openTab(event, tabName) {
  var i, tabcontent, tabbuttons

  // Hide all tab contents
  tabcontent = document.getElementsByClassName('tab-content')
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = 'none'
  }

  // Remove the 'active' class from all tab buttons
  tabbuttons = document.getElementsByClassName('tab-button')
  for (i = 0; i < tabbuttons.length; i++) {
    tabbuttons[i].className = tabbuttons[i].className.replace('tab-active', 'tab-inactive')
  }

  // Show the current tab and add 'active' class to the button that opened the tab
  document.getElementById(tabName).style.display = 'block'
  event.currentTarget.className = event.currentTarget.className.replace('tab-inactive', '') + 'tab-active';
}
