import React from "react"


export default function SuccessNotification({message, onClose}) {

    React.useEffect(() => {
        const timer = setTimeout(() => {
            onClose()
        }, 10000);

        return () => clearTimeout(timer)
    }, [onClose])

    return (
        <div className="success-notification">
        <div className="success-notification-content">
          { !message.includes("Error") && ( <span className="success-icon"><svg width="33" height="33" viewBox="0 0 33 33" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M20.7931 12.3007L14.2031 18.8907L10.6131 15.3107L9.20312 16.7207L14.2031 21.7207L22.2031 13.7207L20.7931 12.3007ZM16.2031 6.7207C10.6831 6.7207 6.20312 11.2007 6.20312 16.7207C6.20312 22.2407 10.6831 26.7207 16.2031 26.7207C21.7231 26.7207 26.2031 22.2407 26.2031 16.7207C26.2031 11.2007 21.7231 6.7207 16.2031 6.7207ZM16.2031 24.7207C11.7831 24.7207 8.20312 21.1407 8.20312 16.7207C8.20312 12.3007 11.7831 8.7207 16.2031 8.7207C20.6231 8.7207 24.2031 12.3007 24.2031 16.7207C24.2031 21.1407 20.6231 24.7207 16.2031 24.7207Z"
                fill="#365E3D" />
            </svg>
          </span>)}
          <span className="success-message">{message}</span>
          <button className="success-close-button" onClick={onClose}><svg width="25" height="25" viewBox="0 0 25 25" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19.2031 7.1307L17.7931 5.7207L12.2031 11.3107L6.61312 5.7207L5.20312 7.1307L10.7931 12.7207L5.20312 18.3107L6.61312 19.7207L12.2031 14.1307L17.7931 19.7207L19.2031 18.3107L13.6131 12.7207L19.2031 7.1307Z"
                fill="black" />
            </svg>
          </button>
        </div>
      </div>
    )

}