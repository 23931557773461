import React from 'react'
import { Link } from 'react-router-dom'

import MessageSvg from '../assets/svg/message.svg'
import ReportSvg from '../assets/svg/reports.svg'
import InferenceSvg from '../assets/svg/inferences.svg'
import { DataContext } from '../contexts/Site'
import DashboardResume from '../components/dashboardResume'
import DashboardCatalogAccess, {
  Card,
} from '../components/dashboardCatalogAccess'

// import image

export default function Dashboard() {
  const { rightSummary, companyName, borrowingCompanies } =
    React.useContext(DataContext)

  return (
    <main className="dashboard-background bg-cover">
      <div className="max-w-[1150px] py-9 px-6 sm:px-9">
        <DashboardResume {...rightSummary} companyName={companyName} />

        <section className="mt-20">
          <h2 className="flex items-center gap-x-5 text-[32px] leading-10 font-medium">
            <img src={MessageSvg} alt="message" width={32} height={32} />
            <span className="text-[#212529]">Recent Messages</span>
            {/*
            <button className="bg-[#FCF1A1] h-8 px-3 text-sm rounded">
              All Messages
            </button>
            */}
          </h2>

          <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-8">
            <RecentMessageCard date={'08/08/2024'} title={'Welcome'}>
              Welcome to the Rightsholder page
            </RecentMessageCard>
            <RecentMessageCard date={'08/08/2024'} title={'Demo'}>
              New messages will appear here.
            </RecentMessageCard>
          </div>
        </section>

        <DashboardCatalogAccess {...rightSummary} companyName={companyName} />

        <section className="mt-20">
          <Card>
            <h2 className="flex items-center flex-wrap gap-y-2 gap-x-4">
              <img src={ReportSvg} alt="Reports" width={32} height={32} />
              <span className="text-2xl sm:text-[32px] leading-10 text-[#304050] font-medium">
                Reports
              </span>
              <Link to="/reportanalytics" className="ml-auto">
                <button className="bg-[#FCF1A1] h-8 px-3 text-sm rounded">
                  Reports & Analytics
                </button>
              </Link>
            </h2>

            <table className="mt-6 text-[#454545]">
              <thead>
                <tr className="*:pl-3 font-bold text-base leading-6">
                  <td className="">No reports, reports will display here</td>
                </tr>
              </thead>
              {/*
              <thead>
                <tr className="*:pl-3 font-bold text-base leading-6">
                  <td className="">Most Recent</td>
                  <td className="w-[237px]">Date Range</td>
                  <td className="w-[90px]">Inferences</td>
                  <td className="w-[140px]">Attributions</td>
                  <td className="w-[140px]"></td>
                </tr>
              </thead>
              <tbody className="mt-2">
                <tr className="*:pl-3 *:leading-6 *:py-0 group *:hover:bg-[#F1F1F1]">
                  <td>company names</td>
                  <td>1/7/24-1/725</td>
                  <td>3,599</td>
                  <td>6,603</td>
                  <td className="opacity-0 group-hover:opacity-100 font-bold">
                    View
                  </td>
                </tr>
                <tr className="*:pl-3 *:leading-6 *:py-0 group *:hover:bg-[#F1F1F1]">
                  <td>company name</td>
                  <td>1/7/24-1/725</td>
                  <td>6,343</td>
                  <td>12,445</td>
                  <td className="opacity-0 group-hover:opacity-100 font-bold">
                    View
                  </td>
                </tr>
                <tr className="*:pl-3 *:leading-6 *:py-0 group *:hover:bg-[#F1F1F1]">
                  <td>company name</td>
                  <td>1/7/24-1/725</td>
                  <td>4,902</td>
                  <td>8,289</td>
                  <td className="opacity-0 group-hover:opacity-100 font-bold">
                    View
                  </td>
                </tr>
              </tbody>
              */}
            </table>
          </Card>
        </section>

        <section className="mt-20">
          <Card>
            <h2 className="flex items-center flex-wrap gap-y-2 gap-x-4">
              <img src={InferenceSvg} alt="Inferences" width={32} height={32} />
              <span className="text-2xl sm:text-[32px] leading-10 text-[#304050] font-medium">
                Generated Tracks
              </span>
              <Link className="ml-auto" to="/generatedtracks">
                <button className="bg-[#FCF1A1] h-8 px-3 text-sm rounded">
                  Generated Tracks
                </button>
              </Link>
            </h2>

            <table className="text-[#454545] mt-9">
              {/*
              <thead className="font-bold">
                <tr className="*:pl-3">
                  <td className="w-[354px]">Latest Inferences</td>
                  <td className="w-[200px]"></td>
                  <td className="w-[90px]"></td>
                  <td className="w-[90px]"></td>
                </tr>
              </thead>
              */}
              <thead className="font-bold">
                <tr className="*:pl-3">
                  <td className="">
                    No inferences, inferences will display here
                  </td>
                </tr>
              </thead>
              {/*
              <tbody className="mt-2">
                <tr className="*:pl-3 *:leading-6 *:py-0 group *:hover:bg-[#F1F1F1]">
                  <td>BMY_MMV1_00332645</td>
                  <td>Company name</td>
                  <td>1m ago</td>
                  <td className="opacity-0 group-hover:opacity-100 font-bold">
                    View
                  </td>
                </tr>
                <tr className="*:pl-3 *:leading-6 *:py-0 group *:hover:bg-[#F1F1F1]">
                  <td>SUN_TMV2_00714774</td>
                  <td>Company name</td>
                  <td>1m ago</td>
                  <td className="opacity-0 group-hover:opacity-100 font-bold">
                    View
                  </td>
                </tr>
                <tr className="*:pl-3 *:leading-6 *:py-0 group *:hover:bg-[#F1F1F1]">
                  <td>BMY_MMV1_00332654</td>
                  <td>Company name</td>
                  <td>1m ago</td>
                  <td className="opacity-0 group-hover:opacity-100 font-bold">
                    View
                  </td>
                </tr>
              </tbody>
              */}
            </table>

            {/*
            <div className="mt-9 text-[#454545]">
              <h3 className="font-bold pl-3">Most Popular Attributions</h3>

              <div className="mt-4 grid grid-cols-3 gap-x-9 gap-y-4 text-[#304050] text-sm">
                {[
                  ['The Eagles', 'Already Gone'],
                  ['The Beatles', 'Hey jude'],
                  ['Lionel Richie', 'Endless Love'],
                  ['Michael Jackson', 'Billie Jean'],
                  [
                    'Creedence Clearwater Revival',
                    'Have You Ever Seen The Rain',
                  ],
                  ['LMFAO', 'Party Rock Anthem'],
                ].map((item) => (
                  <div className="pl-3 flex flex-col" key={item[0]}>
                    <b className="font-bold">{item[0]}</b>
                    <span>{item[1]}</span>
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-9 pl-3 flex flex-col gap-y-2">
              <label className="font-bold text-[#454545]">
                Search Inferences by Prompt
              </label>
              <input
                className="outline-none w-[780px] border border-[#89949F] rounded py-2 px-4 placeholder:text-[#808080]"
                placeholder="Search Prompts"
              />
            </div>
            */}
          </Card>
        </section>
      </div>
    </main>
  )
}

function RecentMessageCard({ date, title, children }) {
  return (
    <Card>
      <div className="text-[#212529]">
        <date className="text-[#212529]">{date}</date>
        <h3 className="mt-[6px] font-bold text-xl">{title}</h3>
        <p className="mt-4">{children}</p>
      </div>
    </Card>
  )
}
