import React from "react"
import { useParams, useNavigate } from "react-router-dom"
import { DataContext } from "../../contexts/Site"
import { getCatalogInfo, calculateTotatAudioLength} from "../../utils"
import Loading from "../../components/Loading"

export default function MetadataOverview(){

    const {id} = useParams()
    const {licensesUsage} = React.useContext(DataContext)
    const [catalog, setCatalog] = React.useState(null)
    const navigate = useNavigate()

    
    React.useEffect(() => {
        getCatalogInfo(licensesUsage, id, setCatalog)
    }, [licensesUsage])

    return (
        <div className="page-main">
            {
                catalog === null ? (
                    <Loading />
                ):
                (
                    <>
                        
                        <h1 className="font-medium text-2xl text-[#000000DE]">Catalog Metadata Overview</h1>
                        <h2 className="mt-6 font-medium text-[40px] leading-[48px] text-[#000000DE]">{`${catalog.license.name} from ${catalog.license.company.Name}`}</h2>
                
                        <div className="mt-4">
                        <div className="flex gap-x-8 text-[#304050]">
                            <span><b className="font-extrabold">Total Music: </b>{catalog.license.Metadata.totalAudioLength}</span>
                            <span><b className="font-extrabold">Total Tracks: </b>{catalog.license.musics.items.length}</span>
                        </div>
                        <div className="flex gap-x-6 mt-4">
                            <button className="px-4 py-2 text-sm bg-[#FCF1A1] rounded" onClick={() => navigate(`/fullMetadataCatalog/${id}`)}>Full Catalog & Metadata</button>
                            <button className="px-4 py-2 text-sm bg-[#FCF1A1] rounded" onClick={() => navigate(`/catalogInfo/${id}`)}>Catalog Details</button>
                        </div>
                        </div>
                
                        <div className="mt-14">
                        <h2 className="font-medium text-2xl text-[#212529]">Data Summary</h2>
                        <div className="mt-6 text-[#304050]">
                            <div><b className="font-extrabold">Total Audio Length:</b> {catalog.license.Metadata.totalAudioLength}</div>
                            <div><b className="font-extrabold">Total Trakcs:</b> {catalog.license.musics.items.length}</div>
                            <div><b className="font-extrabold">Avg. Attributes/Track:</b> {catalog.license.Metadata.AvgAttributesTrack}</div>
                            <div><b className="font-extrabold">Separate Stems:</b> {catalog.license.Metadata.SeperateStems}</div>
                        </div>
                        </div>
                
                        <div className="mt-14">
                        <h2 className="font-medium text-2xl text-[#212529]">Metadata Statistics</h2>
                        <div className="mt-6 grid grid-cols-3 gap-x-12 text-[#304050] text-[13px]">
                            <div className="flex flex-col gap-y-5">
                            <p>
                                <b className="font-extrabold">Total Tracks</b>
                                <br />
                                {catalog.license.musics.items.lenght}
                            </p>
                
                            <p>
                                <b className="font-extrabold">Unique Artists</b>
                                <br />
                                {catalog.license.Metadata.UniqueArtists}
                            </p>
                
                            <div>
                                <b className="font-extrabold">Genres by Percentage</b>
                                <ul className="">
                                    {
                                        catalog.license.Metadata.GenresPercentage !== undefined && catalog.license.Metadata.GenresPercentage.map((item) => {
                                            return (
                                                <li>{item}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            </div>
                
                            <div className="flex flex-col gap-y-5">
                            <p>
                                <b className="font-extrabold">Average number of 2nd Level Keys per Track:</b>
                                <br />
                                {catalog.license.Metadata.AvgSgnKey}
                            </p>
                
                            <div>
                                <b className="font-extrabold">Unique 2nd Level Keys w/ Quantity:</b>
                                <ul>
                                {
                                        catalog.license.Metadata.UniqueSngKey !== undefined && catalog.license.Metadata.UniqueSngKey.map((item) => {
                                            return (
                                                <li>{item}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            </div>
                
                            <div>
                            <b className="font-extrabold">Artists by Number of Tracks</b>
                            <ul>
                                {
                                        catalog.license.Metadata.ArtistByNumberOfTrack !== undefined && catalog.license.Metadata.ArtistByNumberOfTrack.map((item) => {
                                            return (
                                                <li>{item}</li>
                                            )
                                        })
                                    }
                            </ul>
                            </div>
                        </div>
                        </div>
                  </>   
                )
            }
        </div>
    )

}