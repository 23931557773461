import React from "react"
import Loading from "../components/Loading"
import Licensee from  "../components/licensee"
import { DataContext } from "../contexts/Site"
import AccessHistory from "../components/accessHistory"
import "../style/manage-access.style.css"
import RedirectLogin from "../components/RedirectLogin"



export default function ManageAccess() {
    const {companyName, borrowingCompanies} = React.useContext(DataContext)
    return (
        companyName === '' || borrowingCompanies === '' ? (
            <Loading />
        ): (
            <div className="page-main">
                
                <p>{companyName}</p>
                <h1> Manage Access</h1>
                <Licensee expand={false}/>
                <AccessHistory />
            </div>
            
        )
        
    )
}