import React from "react"

import "../style/data-catalog.style.css"
import Loading from "../components/Loading"
import CatalogResume from "../components/catalogResume"
import DataSummary from "../components/datasummary"
import Licensee from  "../components/licensee"
import { DataContext } from "../contexts/Site"
import ModelTrainingHistory from "../components/ModelTrainingHistory"
import { Hub } from 'aws-amplify/utils';

import { useNavigate } from "react-router-dom"
import RedirectLogin from "../components/RedirectLogin"


export default function DataCatalogs() {


    const {rightSummary, companyName, borrowingCompanies} = React.useContext(DataContext)



    return (     
        rightSummary === null || borrowingCompanies === null ? (
            <Loading />
        ): (
            <div className="page-main overflow-auto">
                
                <CatalogResume {...rightSummary} companyName={companyName} />

                <DataSummary {...rightSummary} />

                <Licensee expand={true}/>
                
                <ModelTrainingHistory/>
            </div>
            
        )
       
    )
}
