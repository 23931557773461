import React from "react"
import { DataContext } from "../../contexts/Site"
import { useParams } from "react-router-dom"
import { generateClient } from "aws-amplify/api"
import { listLicenseMusics } from "../../modified-graphql/queries"
import DropdownMenuTrainingSet from "../../components/aiCompany/DropdownMenuTrainingSet"
import addToList from "../../assets/svg/add-to-list.svg"
import filtersvg from "../../assets/svg/filter.svg"
import Select from 'react-select';

const API = generateClient()

export default function FullMetadataCatalog(){

    const {id} = useParams()
    const {licensesUsage} = React.useContext(DataContext)
    const [checkboxes, setCheckboxes] = React.useState({})
    const [musicList, setMusicList] = React.useState([])
    const [licenseToCatalog, setLicenseToCatalog] = React.useState({})
    const [selectedRows, setSelectedRows] = React.useState([])
    const [filters, setFilters] = React.useState({artist: '', genres: [] }) 
    const filteredMusic = musicList.filter(
        (row) =>
          (filters.artist === '' || row.artist.includes(filters.artist)) &&
          (filters.genres.length === 0 || filters.genres.includes(row.genre))
      );

    //  Here I handle all the selected row and the select all checkbox 
    // ###### I start here {select rows and checkboxo}
    React.useEffect(() => {
        const selectAllCheckbox = document.getElementById('select-all-checkbox');
        if (selectedRows.length > 0 && selectedRows.length < musicList.length) {
        selectAllCheckbox.indeterminate = true;
        } else {
        selectAllCheckbox.indeterminate = false;
        }

    }, [selectedRows, musicList.length])
    
    const handleSelectRow = (id) => {
        setSelectedRows((prevSelectedRows) => {
          if (prevSelectedRows.includes(id)) {
            return prevSelectedRows.filter((rowId) => rowId !== id);
          } else {
            return [...prevSelectedRows, id];
          }
        });
      };

      const handleSelectAll = () => {
        if (selectedRows.length === musicList.length) {
          setSelectedRows([]);
        } else {
          setSelectedRows(musicList.map((row) => row.id));
        }
      };

    const isSelected = (id) => selectedRows.includes(id);
    
    // ###### I end here {select rows and checkboxo}


    // Here I Handle the catalog filtering and showing
    // ###### I start here {catalog showing and filtering}
    const labelElement = licensesUsage !== null && licensesUsage.map((item) => {
        return (
            <label key={item.id} >
                <input type="checkbox"
                        name={item.id}
                        checked={checkboxes[item.id] || false}
                        onChange={handleCatalogSelection}
                    className="w-5 h-5 accent-[#245B9E]" />
            {item.license.name} from {item.license.company.Name}</label>
        )
    })

    function handleCatalogSelection(event){
        const { name, checked } = event.target;
        setCheckboxes((prevCheckboxes) => ({
        ...prevCheckboxes,
        [name]: checked
        }));
        console.log(checkboxes)
    }


    // ###### I end here {catalog showing and filtering}


    // Here I handle fetching and showing musics
    // ###### I start here {fetching musics}
    React.useEffect(() => {
        fetchingCatalogMusics()
    }, [checkboxes])

    async function fetchingCatalogMusics(){
        let apiData
        

        const toInclude = Object.keys(checkboxes).filter(key => checkboxes[key])
        console.log(toInclude)

        const filteredCatalog = toInclude.map( key => {
            const item = licensesUsage.find(element => element.id === key )
            return {licenseMusicsId: {eq: item.license.id }}
        })


        console.log(filteredCatalog)
        console.log(licenseToCatalog)
        
        apiData = await API.graphql({query: listLicenseMusics, variables: 
                    { filter: {
                        or: filteredCatalog
                        }
                    }}
                 )
        setMusicList(Object.values(apiData.data.listLicenseMusics.items.reduce((accumulatorDict, current) => {

            accumulatorDict[current.music.id] = { ...current.music , catalog: `${current.licence.name} from ${current.licence.company.Name}`}
            return accumulatorDict
        }, {})))

    }


    const musicListElement = filteredMusic.map((item) => {
        return (
            <tr className="*:py-3 *:pl-6 *:first-child:pl-5 hover:bg-[#F1F1F1]" key={item.id}>
                <td>{item.title}</td>
                <td>{item.artist}</td>
                <td>{item.data}</td>
                <td>{item.length}</td>
                <td>{item.catalog}</td>
                <td>{item.genre}</td>
                <td>
                    <DropdownMenuTrainingSet {...item}  ismusic={true}/>  
                                    
                </td>
                <td>
                    <input 
                        type="checkbox"
                        checked={isSelected(item.id)}
                        onChange={() => handleSelectRow(item.id)} 
                    />
                </td>
            </tr>
        )
    })

    // ###### I end here {fetching musics}

    // Filtering of the values

    const handleFilterChange = (e) => {
        setFilters({
          ...filters,
          [e.target.name]: e.target.value,
        });
      };
    
      const handleGenreChange = (selectedOptions) => {
        setFilters({
          ...filters,
          genres: selectedOptions ? selectedOptions.map(option => option.value) : [],
        });
      };
    
      
    
      const uniqueValues = (key) => {
        return [...new Set(musicList.map(item => item[key]))];
      };

      const genreOptions = uniqueValues('genre').map(genre => ({
        value: genre,
        label: genre
      }));

    return (
    <div className="page-main">
        
        <h1 className="font-medium text-2xl text-[#000000DE]">Training Data</h1>
        <h2 className="mt-10 font-medium text-[40px] leading-[48px] text-[#000000DE]">Licensed Catalog Explorer</h2>

        <p className="mt-6 font-extrabold text-[#304050]">Include Data From:</p>
        <div className="mt-2 flex flex-col gap-y-1 pl-5 *:text-2xl *:font-medium *:leading-7 *:flex *:items-center *:gap-x-3 text-[#212529]">
            {labelElement}
        </div>

        <div className="mt-9">
            <div className="flex items-center">
            <div className="border-[#89949F] border rounded flex">
                <input placeholder="Search Data"
                className="rounded-l h-10 w-[400px] placeholder:text-[#808080] px-4 outline-none border-none" />
                <button className="w-12 h-10 border-l border-solid border-t-0 border-r-0 border-b-0 border-[#89949F] rounded-r flex justify-center items-center">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M10.8372 10.0931H10.3106L10.1239 9.9131C10.7772 9.1531 11.1706 8.16643 11.1706 7.0931C11.1706 4.69977 9.23057 2.75977 6.83724 2.75977C4.44391 2.75977 2.50391 4.69977 2.50391 7.0931C2.50391 9.48643 4.44391 11.4264 6.83724 11.4264C7.91057 11.4264 8.89724 11.0331 9.65724 10.3798L9.83724 10.5664V11.0931L13.1706 14.4198L14.1639 13.4264L10.8372 10.0931ZM6.83724 10.0931C5.17724 10.0931 3.83724 8.7531 3.83724 7.0931C3.83724 5.4331 5.17724 4.0931 6.83724 4.0931C8.49724 4.0931 9.83724 5.4331 9.83724 7.0931C9.83724 8.7531 8.49724 10.0931 6.83724 10.0931Z"
                    fill="black" />
                </svg>

                </button>
            </div>


            <button className="ml-6 px-4 py-2 text-sm bg-[#FCF1A1] h-9 rounded flex items-center gap-x-2">
                <img src={filtersvg} alt="" />
                Clear Filters
            </button>

            <button className="ml-auto px-4 py-2 text-sm bg-[#2D73C8] rounded flex items-center gap-x-2 text-white">
                <img src={addToList} alt="" />
                Add Training data set
            </button>
            <label className="cursor-pointer ml-6 block flex items-center gap-x-2 text-sm">
               {selectedRows.length} Selected (3h 5m) 
            </label>
            <input 
                        type="checkbox"
                        id="select-all-checkbox"
                        checked={selectedRows === filteredMusic.length}
                        onChange={handleSelectAll}
                    />
            
            </div>
            <table className="w-full">
            <thead className="shadow-[0px_6px_8px_0px_#0000001A] border-b border-b-[#CED4DA] text-[#304050]">
                <tr className="*:py-6 *:text-left text-sm">
                <th className="w-[290px] pl-5">Track</th>
                <th className="w-[290px] pl-6">Artist 
                
                    <select name="artist" onChange={handleFilterChange} value={filters.artist}>
                    <option value="">All</option>
                    {uniqueValues('artist').map((artist) => (
                    <option key={artist} value={artist}>{artist}</option>
                    ))}
                    </select>
                    
                </th>
                <th className="w-[120px] pl-6">Date</th>
                <th className="w-[120px] pl-6">Length</th>
                <th className="w-[120px] pl-6">Catalog</th>
                <th className="w-[120px] pl-6">Genre
                    <Select
                    isMulti
                    name="genres"
                    options={genreOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleGenreChange}
                    value={genreOptions.filter(option => filters.genres.includes(option.value))}
                />
                </th>
                <th className="w-[120px] pl-6"></th>
                </tr>
            </thead>
            <tbody className="text-[#304050] text-sm *:border-b *:border-b-[#CED4DA]">
                {musicListElement}
            </tbody>
            </table>
        </div>
    </div>
    )

}