import React from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { TbLogout } from 'react-icons/tb'
import Notification from './Notification'
import { useClickAway } from '@uidotdev/usehooks'
import { Link } from 'react-router-dom'
import RedirectLogin from './RedirectLogin'

// import image
import notificationImage from '../assets/svg/notification.svg'
import anonymousUserImage from '../assets/svg/anno-user.svg'
import chevronDownImage from '../assets/svg/chevron-down.svg'

import { LogoLink } from '../Routing'

export default function Header(props) {
  const { user, signOut } = useAuthenticator((context) => [
    context.user,
    context.signOut,
  ])
  const [isOpen, setIsOpen] = React.useState(false)
  const [openNotification, setOpenNotification] = React.useState(false)
  // console.log(user)

  const ref = useClickAway(() => {
    setIsOpen(false)
  })

  return (
    <header className='sticky top-0 z-10 bg-[#FCFDFD]'>
      <RedirectLogin/>
      <LogoLink
        className="lg:hidden ml-4 mr-auto"
        imgClassName={'max-sm:w-[172px]'}
      />
      <button onClick={() => setOpenNotification(true)}><img className="header--notification--image" src={notificationImage} /></button>
      <img className="header--anonymous--image ml-6 rounded" src={anonymousUserImage} />
      <div
        className="auth-user relative max-sm:!px-[10px]"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="max-sm:hidden">{user.username}</span>
        <img src={chevronDownImage} width="10" height="10" />
        {isOpen && (
          <ul
            ref={ref}
            className="py-2 rounded border border-gray-300 border-solid flex flex-col w-[280px] absolute -top-1 right-0 bg-white"
          >
            <li>
              <Link
                to="/account-settings"
                className="w-full py-3 px-5 flex items-center gap-x-1 hover:bg-gray-50"
              >
                Account Settings
              </Link>
            </li>
            <li>
              <button
                onClick={signOut}
                className="w-full py-3 px-5 flex items-center gap-x-1 hover:bg-gray-50"
              >
                Sign Out
              </button>
            </li>
          </ul>
        )}
      </div>
      <Notification openNotification={openNotification} setOpenNotification={setOpenNotification}/>
    </header>
  )
}
