import React from 'react'
import Modal from './modals/Modal'
import '../style/notification.css'
import openTab from '../modal-ui'
import Loading from './Loading'


export default function MusicDetails(props) {
    const {musicData, isMusicDetailsOpen, setMusicDetailsOpen} = props
    
    const closeModal = () => {
        setMusicDetailsOpen(false);
    };

    return (
        <Modal isOpen={isMusicDetailsOpen} onClose={closeModal}>
            <div className="p-6 bg-white rounded-lg w-[600px] max-w-[90%] shadow-[0px_16px_48px_0px_#0000002D;] border border-black/20">
                <div className="flex items-start mb-9">
                <div className="flex items-center gap-x-3 w-full ddcapped">
                    <img src="/assets/music-disc.png" width={48} height={48} className='w-12 h-12' />
                    <div className="track-info text-[#304050]">
                    <h1 className="text-[32px] leading-8 font-medium">{musicData.title}</h1>
                    <p className="text-xl leading-6 font-bold !text-[#304050]">{musicData.artist}</p>
                    </div>
                </div>
                <button className="close-button" onClick={closeModal}><svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19.4922 7.02609L18.0822 5.61609L12.4922 11.2061L6.90219 5.61609L5.49219 7.02609L11.0822 12.6161L5.49219 18.2061L6.90219 19.6161L12.4922 14.0261L18.0822 19.6161L19.4922 18.2061L13.9022 12.6161L19.4922 7.02609Z"
                        fill="black" />
                    </svg>
                </button>
                </div>

                <div className="tabs text-sm mb-9">
                <button onClick={(event) => openTab(event, 'tab1')} className="tab-button py-2 px-4 tab-active">Inferences with Attribution
                    (0)</button>
                <button onClick={(event) => openTab(event, 'tab2')} className="tab-button py-2 px-4 tab-inactive">Metadata</button>
                <div className="border-b border-b-[#DEE2E6] grow"></div>
                </div>
                <div id="tab1" className="tab-content active text-sm text-center text-gray-500 h-[470px]">
                No attributions
                </div>
                <div id="tab2" className="tab-content">
                <table className="metadata-table music-detail-table">
                   
                    {
                        musicData === '' ? (
                            <Loading />
                        ): 
                        (<tbody>
                            <tr>
                                <th className="metadata-header">midi_file_name</th>
                                <td className="metadata-data">{musicData.midi_file_name}</td>
                                </tr>
                                <tr>
                                <th className="metadata-header">time_signature</th>
                                <td className="metadata-data">{musicData.time_signature}</td>
                                </tr>
                                <tr>
                                <th className="metadata-header">note_swing</th>
                                <td className="metadata-data">{musicData.note_swing}</td>
                                </tr>
                                <tr>
                                <th className="metadata-header">key_scale</th>
                                <td className="metadata-data">{musicData.key_scale}</td>
                                </tr>
                                <tr>
                                <th className="metadata-header">bpm</th>
                                <td className="metadata-data">{musicData.bpm}</td>
                                </tr>
                                <tr>
                                <th className="metadata-header">tempo</th>
                                <td className="metadata-data">{musicData.tempo}</td>
                                </tr>
                                <tr>
                                <th className="metadata-header">bpm_min</th>
                                <td className="metadata-data">{musicData.bpm_min}</td>
                                </tr>
                                <tr>
                                <th className="metadata-header">bpm_max</th>
                                <td className="metadata-data">{musicData.bpm_max}</td>
                                </tr>
                                <tr>
                                <th className="metadata-header">mood</th>
                                <td className="metadata-data">{musicData.mood}</td>
                                </tr>
                                <tr>
                                <th className="metadata-header">energy</th>
                                <td className="metadata-data">{musicData.energy}</td>
                                </tr>
                                <tr>
                                <th className="metadata-header">track_type</th>
                                <td className="metadata-data">{musicData.track_type}</td>
                                </tr>
                                <tr>
                                <th className="metadata-header">description</th>
                                <td className="metadata-data">{musicData.description}</td>
                                </tr>
                                <tr>
                                <th className="metadata-header">keywords</th>
                                <td className="metadata-data">{musicData.keywords}</td>
                                </tr>
                                <tr>
                                <th className="metadata-header">instrumentation</th>
                                <td className="metadata-data">{musicData.instrumentation}</td>
                                </tr>
                         </tbody>
                        )
                    }
                    
                   
                </table>
                </div>
            </div>
        </Modal>
    )
}
