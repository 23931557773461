import React from "react";
import { EyeFilledIcon } from '../icons/EyeFilledIcon'
import { EyeSlashFilledIcon} from '../icons/EyeSlashFilledIcon'
import { Input } from '@nextui-org/input'

export default function MusicAIPassword(props){

    const {fieldValidationErrors, name, onBlur,onChange, label} = props

    const [showPassword, setShowPassword] = React.useState(false);
    const [inputValue, setInputValue] = React.useState('');

    const toggleShowPassword = () => {
    setShowPassword(!showPassword);
    };

    const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (onChange) {
        onChange(e);
    }
    };
    return (
    <>
        <Input
            radius="sm"
            type={showPassword ? "text" : "password"}
            label={label}
            labelPlacement={'outside'}
            placeholder=" "
            name={name}
            onBlur={onBlur}
            onChange={handleInputChange}
            value={inputValue}
            endContent={
                <div type="button" onClick={toggleShowPassword} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                {showPassword ? (
                    <EyeSlashFilledIcon className="text-2xl text-default-400" />
                ) : (
                    <EyeFilledIcon className="text-2xl text-default-400" />
                )}
                </div>
            }
            />
        {fieldValidationErrors?.map((error) => (
        <p key={error} role="alert">{error}</p>
        ))}
    </>
    )

}