import React from "react"
import Modal from "./modals/Modal"
import '../style/notification.css' 

export default function Notification(props) {

    const {openNotification, setOpenNotification} = props
    
    const closeModal = () => {
        setOpenNotification(false)
    };

    return(
        <div>
            <Modal isOpen={openNotification} onClose={closeModal}>
            <div  className="border shadow-md p-6 bg-white rounded w-[490px]">
            <div className="text-xl font-medium text-[#212529] flex items-center justify-between">
            Notification
            <button onClick={closeModal}>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                        d="M19.3516 7.1307L17.9416 5.7207L12.3516 11.3107L6.76156 5.7207L5.35156 7.1307L10.9416 12.7207L5.35156 18.3107L6.76156 19.7207L12.3516 14.1307L17.9416 19.7207L19.3516 18.3107L13.7616 12.7207L19.3516 7.1307Z"
                        fill="black" />
                    </svg>
                </button>
            </div>
                <br/>
                <h2> Coming Soon... </h2>
            </div>
        </Modal>
        </div>
        
    )
}