import React, { useRef } from 'react'

import '../style/data-catalog-explorer.style.css'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { generateClient } from 'aws-amplify/api'
import { fetchAuthSession } from 'aws-amplify/auth'
import { DataContext } from '../contexts/Site'
import Loading from '../components/Loading'
import MusicDetails from '../components/MusicDetails'
import magnifier from '../assets/svg/filter.svg'
import { IoFilterOutline } from 'react-icons/io5'
import { GoSearch } from 'react-icons/go'
import { listMusic, getMusicByCompany } from '../modified-graphql/queries'
import { MdMoreHoriz } from 'react-icons/md'
import { calculateTotatAudioLength } from '../utils'
import { LuAirVent } from 'react-icons/lu'
import DropdownSelect from '../components/dropdown-select'
import RedirectLogin from "../components/RedirectLogin";
import { IoIosArrowRoundUp } from "react-icons/io";
import { IoIosArrowRoundDown } from "react-icons/io";


const API = generateClient()

export default function ExploreDataCatalogs() {
  const { companyId, companyName, rightSummary } = React.useContext(DataContext)
  const [musicList, setMusicList] = React.useState([])
  const [isMusicDetailsOpen, setMusicDetailsOpen] = React.useState(false)
  const [musicData, setMusicData] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [nextToken, setNextToken] = React.useState(null)
  let clear = false
  const [searchField, setSearchField] = React.useState('')

  const limit = 1000
  const more = 10
  const [sortedKey, setSortedKey] = React.useState('')
  const [sortedOrder, setSortedOrder] = React.useState('asc')
  const [sortIt, setSortIt] = React.useState(false)

  const [searchKeyword,setSearchKeyword] = React.useState('All Keywords') 
  const [musicIds, setMusicIds] = React.useState(new Set())
  let isFetching = false
  
  // console.log(companyId)
  React.useEffect(() => {
    fetchMusic(null, false)
  }, [companyId])

  async function fetchMusic(token, clear) {
    if (isFetching){
        console.log('Fetch is already in progress');
        return 
    }
    isFetching = true
    setError(null)
    setLoading(true)
    const theLimit = token === null ? limit : more
    // Construction of search component
    const searchTerms = (!clear ? searchField : '').split(' ').map((key) => {
      return { searchField: { contains: key.toLowerCase() } }
    })
    const searchStrings = (!clear ? searchField : '').split(' ')
    let countMusics = 0
    try {
      do{
            const apiData = await API.graphql({
              query: getMusicByCompany,
              variables: {
              companyId: companyId,
              filter: {
                  and: [
                  ...searchTerms,
                  ],
              },
              limit: theLimit,
              nextToken: token,
              },
          })
          const musics = apiData.data.getCompany.musics.items
          
          console.log(musics.length)
          const filterMusics = searchField === '' ? musics : searchKeyword === 'All Keywords' ? musics : filterSearchMusic(musics, searchStrings)

          const newMusics = filterMusics.filter(music => !musicIds.has(music.id))
          
          if (token === null) {
              
              setMusicList(filterMusics)
              const newSet = new Set()
              filterMusics.forEach(music => newSet.add(music.id))
              
              setMusicIds(newSet)
          } 
          else {
              if (newMusics.length > 0) {
                  setMusicList(prevMusicList => [...prevMusicList, ...newMusics]);
                  setMusicIds(prevSet => {
                  const updatedSet = new Set(prevSet);
                  newMusics.forEach(music => updatedSet.add(music.id));
                  console.log(updatedSet)
                  return updatedSet;
                  });

              }
          }

          countMusics = countMusics + newMusics.length

          
        token = apiData.data.getCompany.musics.nextToken
        // console.log(token ? token.slice(-7) : token)
      } while(token && countMusics<theLimit)
      
      if(sortedKey !== ''){
          setSortIt(true)
      }
      setNextToken(token)
    } catch (err) {
      console.log(err)
      setError(err)
    } finally {
      console.log('I am in')
      setLoading(false)
      isFetching = false
    }
  }


  
  function sortMusics(key, sortedOrder){
    const sortedMusic = [...musicList].sort((a, b) => {
        const aValue = a[key] ? a[key].toLowerCase() : '';
        const bValue = b[key] ? b[key].toLowerCase() : '';
        if (aValue < bValue) return sortedOrder === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortedOrder === 'asc' ? 1 : -1;
        return 0;
      });
      setMusicList(sortedMusic);
  }

  function sort(key){
    if(sortedKey === ''){
        // This mean there is not active sort. Then we sort by asc. 
        setSortedOrder('asc')
        setSortedKey(key)
    } else if (sortedKey === key ){
        // Change the order of sorting. 
        setSortedOrder((previousOrder) => (previousOrder === 'asc' ? 'desc' : 'asc'))
    } else {
        // this means the sorted key is different from the key then we sort by asc 
        setSortedOrder('asc')
        setSortedKey(key)
    }
    setSortIt(true)
  }

  React.useEffect(() => {
    if(sortIt){
        sortMusics(sortedKey, sortedOrder)
        setSortIt(false)
    }
  }, [sortIt])

  React.useEffect(() => {
    const loadMoreData = () => {
      if (
        window.innerHeight + window.scrollY >=
          document.body.offsetHeight - 1000 &&
          document.body.offsetHeight - 1000 &&
        nextToken !== null &&
        !loading
      ) {
        console.log('Fetching more data...')
        setLoading(true)
        fetchMusic(nextToken, false)
      }
    }

    document?.addEventListener('scroll', loadMoreData)

    return () => document.removeEventListener('scroll', loadMoreData)
  }, [nextToken !== null, loading])
  // function handleMore() {
  //     if (nextToken !== null) {
  //         fetchMusic(nextToken, false)
  //     }
  // }

  function clearFilter() {
    setSearchField('')
    fetchMusic(null, true)
  }

  function openDetail(music) {
    setMusicData(music)
    setMusicDetailsOpen(true)
  }

  function handleKeyUp(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      fetchMusic(null, false)
    }
  }

  function handleDropdownChange(index){
    console.log(index)
    setSearchKeyword(index)
  }

  function filterSearchMusic(listToFilter, searchStrings){
    console.log(searchKeyword)
    return listToFilter.filter(music => {
        switch(searchKeyword){
            case 'Track Name': 
                // We search by title
                return searchStrings.every( str => music.title.toLowerCase().includes(str.toLowerCase()))
            case 'Artist':
                return searchStrings.every( str => music.artist.toLowerCase().includes(str.toLowerCase()))
            case 'Album':
                return searchStrings.every( str => music.album.toLowerCase().includes(str.toLowerCase()))
            case 'Genre':
                return searchStrings.every( str => music.genre.toLowerCase().includes(str.toLowerCase()))
            default:
                return false
        }
    })
    
  }

  return companyName === null || rightSummary === null ? (
    <Loading/>
  ) : (
    <div className="page-main">
                
      <section className="overview">
        <p>Catalog Explorer</p>
        <h1>{companyName}</h1>
      </section>
      <section className="catalog-detail">
        <div className="metric-overview text-[#304050]">
          <span>
            <b>Total Audio Length: </b>
            {rightSummary.totalAudioLength}
          </span>
          <span>
            <b>Total Tracks: </b>
            {rightSummary.TotalTracks}
          </span>
        </div>
        <div className="action-form flex items-center">
          <div className="flex">
            <DropdownSelect
              options={[
                'All Keywords',
                'Track Name',
                'Artist',
                'Album',
                'Genre',
              ]}
                value={searchKeyword}
                onChange={handleDropdownChange}
            />
            <div className="input-wrapper">
              <input
                type="text"
                value={searchField}
                onChange={(event) => setSearchField(event.target.value)}
                className="search-box"
                placeholder="Search Catalog Data"
                onKeyUp={handleKeyUp}
              />
              <button>
                <GoSearch
                  onClick={() => fetchMusic(null, false)}
                  alt="search"
                  width="16"
                  height="16"
                />
              </button>
            </div>
          </div>
          <button className="yellow-button !h-9" onClick={clearFilter}>
            <IoFilterOutline />
            Clear Filters
          </button>
        </div>
        <>
          <table className="data-table catalog-table">
            <thead>
              <tr className='*:min-w-[76px]'>
                <th>Track</th>
                <th>Artist
                    <button onClick={() => sort('artist')} >
                        {sortedKey !== 'artist' ? (<IoIosArrowRoundUp />) : sortedOrder === 'asc' ? (<IoIosArrowRoundUp />): ( <IoIosArrowRoundDown />) } 
                    </button>
                </th>
                <th>Album
                    <button onClick={() => sort('album')} >
                        {sortedKey !== 'album' ? (<IoIosArrowRoundUp />) : sortedOrder === 'asc' ? (<IoIosArrowRoundUp />): ( <IoIosArrowRoundDown />) } 
                    </button>
                </th>
                <th>Genre
                    <button onClick={() => sort('genre')} >
                        {sortedKey !== 'genre' ? (<IoIosArrowRoundUp />) : sortedOrder === 'asc' ? (<IoIosArrowRoundUp />): ( <IoIosArrowRoundDown />) } 
                    </button>
                </th>
                <th>Date</th>
                <th>Length</th>
                <th>Attributions</th>
                <th></th>
              </tr>
            </thead>
            {
              <tbody>
                {musicList.map((music) => (
                  <tr key={music.id}>
                    <td>{music.title}</td>
                    <td>{music.artist}</td>
                    <td>{music.album}</td>
                    <td>{music.genre}</td>
                    <td>{music.data}</td>
                    <td>{music.length}</td>
                    <td>0</td>
                    <td>
                      <button
                        className="detail-button"
                        onClick={() => openDetail(music)}
                      >
                        Details
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            }
          </table>
        </>
        {loading && <Loading />}
        {!loading && !musicList?.length && <div className='text-center min-h-48 justify-center flex items-center'>No results found</div>}
        {/* {
                    loading ? 
                        (
                            <Loading />
                        ) :
                        (
                            
                            
                            
                        )
                    
                } */}
        {/* {
                    nextToken !== null && (
                        <div className="flex items-center" >
                            <button className="yellow-button !h-9" onClick={handleMore}> 
                                More <MdMoreHoriz />
                            </button>
                        </div>
                    )
                } */}
      </section>
      <MusicDetails
        musicData={musicData}
        isMusicDetailsOpen={isMusicDetailsOpen}
        setMusicDetailsOpen={setMusicDetailsOpen}
      />
    </div>
  )
}

// function TableHeaderCell({ children, className, sorted, dir, onClick }) {
//   return (
//     <th
//       onClick={onClick}
//       className={className + ' cursor-pointer select-none min-w-[80px]'}
//     >
//       {children}
//       {sorted && <SortIcon dir={dir} />}
//     </th>
//   )
// }

// function SortIcon({ dir }) {
//   return (
//     <img
//       src={ArrowUpImage}
//       alt="arrow"
//       width={16}
//       height={16}
//       className={`inline ml-1 w-4 h-4 -translate-y-[2px] ${
//         dir === 'asc' ? '' : 'desc' ? 'rotate-180' : ''
//       }`}
//     />
//   )
// }
